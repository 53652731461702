import { AuthService, ListService, PagedResultDto } from '@abp/ng.core';
import { UsersComponent, eIdentityComponents } from '@abp/ng.identity';
import { GetTenantsInput, TenantDto, TenantService } from '@abp/ng.tenant-management/proxy';
import { ConfirmationService, ToasterService, Confirmation } from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription, finalize } from 'rxjs';
import { TenantsComponent, eTenantManagementComponents } from '@abp/ng.tenant-management';
import { eFeatureManagementComponents } from '@abp/ng.feature-management';
import { DatePipe } from '@angular/common';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { CommonService } from 'src/core/services/common.service';

@Component({
  selector: 'app-custom-tenants',
  templateUrl: './custom-tenants.component.html',
  styleUrls: ['./custom-tenants.component.scss'],
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eTenantManagementComponents.Tenants,
    },
    {
      provide: TenantsComponent,
      useExisting: CustomeTenantComponent,
    },
  ],
})
export class CustomeTenantComponent implements OnInit {
  data: PagedResultDto<TenantDto> = { items: [], totalCount: 0 };

  selected!: TenantDto;

  tenantForm!: UntypedFormGroup;

  isModalVisible!: boolean;

  visibleFeatures = false;

  providerKey!: string;

  modalBusy = false;

  featureManagementKey = eFeatureManagementComponents.FeatureManagement;

  isDarkTheme = false;

  get hasSelectedTenant(): boolean {
    return Boolean(this.selected.id);
  }

  onVisibleFeaturesChange = event => {
    this.visibleFeatures = event;
    if (!this.visibleFeatures) {
      this.isAddEditFeatureOpened = false;
    }
  };
  isAddEditOpened = false;
  isAddEditFeatureOpened = false;
  @ViewChild('dialog') dialog: any;
  @ViewChild('dialogFeature') dialogFeature: any;

  editActionSubscription: Subscription;
  deletActionSubscription: Subscription;
  permissionActionSubscription: Subscription;
  searchText = '';

  constructor(
    public readonly list: ListService<GetTenantsInput>,
    private injector: Injector,
    private confirmationService: ConfirmationService,
    private service: TenantService,
    private toasterService: ToasterService,
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    public authService: AuthService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.hookToQuery();
    this.isDarkTheme = localStorage.getItem('currentSelectedTheme') === 'dark';
    this.commonService.themeColorChange.subscribe(newTheme => {
      this.isDarkTheme = newTheme === 'dark';
    });
    this.editActionSubscription = this.commonService.editChange.subscribe(data => {
      this.editTenant(data);
    });
    this.permissionActionSubscription = this.commonService.permissionChange.subscribe(data => {
      this.openFeaturesModal(data);
    });
    this.deletActionSubscription = this.commonService.deleteChange.subscribe(data => {
      this.delete(data);
    });
  }

  open() {
    this.isAddEditOpened = true;
    this.commonService.astricValidationColor(this.cdr, this.elementRef, this.renderer);
  }

  close(status: string): void {
    this.isAddEditOpened = false;
  }

  closeFeature(status: string): void {
    this.isAddEditFeatureOpened = false;
  }

  ngOnDestroy(): void {
    this.editActionSubscription?.unsubscribe();
    this.deletActionSubscription?.unsubscribe();
    this.permissionActionSubscription?.unsubscribe();
  }

  private createTenantForm() {
    const data = new FormPropData(this.injector, this.selected);
    this.tenantForm = generateFormFromProps(data);
  }

  addTenant() {
    this.selected = {} as TenantDto;
    this.createTenantForm();
    this.isModalVisible = true;
    this.open();
  }

  editTenant(id: string) {
    this.service.get(id).subscribe(res => {
      this.selected = res;
      this.createTenantForm();
      this.isModalVisible = true;
      this.open();
    });
  }

  save() {
    if (!this.tenantForm.valid || this.modalBusy) return;
    this.modalBusy = true;

    const { id } = this.selected;
    if (
      this.tenantForm.value.extraProperties.TrialExpiredOn !== null &&
      this.tenantForm.value.extraProperties.TrialExpiredOn !== undefined
    ) {
      this.tenantForm.value.extraProperties.TrialExpiredOn = this.datePipe.transform(
        this.tenantForm.value.extraProperties.TrialExpiredOn,
        'yyyy-MM-ddTHH:mm:ss',
      );
    }
    (id
      ? this.service.update(id, { ...this.selected, ...this.tenantForm.value })
      : this.service.create(this.tenantForm.value)
    )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isModalVisible = false;
        this.isAddEditOpened = false;
        this.list.get();
      });
  }

  delete(data: any) {
    swal({
      title: 'Are you sure?',
      text: `Tenant ${data.name} will be deleted. Do you confirm that?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        this.service.delete(data.id).subscribe(() => {
          this.toasterService.success(
            'AbpUi::SuccessfullyDeleted',
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.list.get();
        });
      }
    });
  }

  hookToQuery() {
    this.list
      .hookToQuery(query => this.service.getList(query))
      .subscribe(res => {
        this.data = res;
      });
  }

  onSharedDatabaseChange(value: boolean) {
    if (!value) {
      setTimeout(() => {
        const defaultConnectionString = document.getElementById(
          'defaultConnectionString',
        ) as HTMLInputElement;
        if (defaultConnectionString) {
          defaultConnectionString.focus();
        }
      }, 0);
    }
  }

  openFeaturesModal(providerKey: any) {
    this.providerKey = providerKey.id;
    setTimeout(() => {
      this.visibleFeatures = true;
      this.isAddEditFeatureOpened = true;
    }, 0);
  }

  sort(data: any) {
    const { prop, dir } = data.sorts[0];
    this.list.sortKey = prop;
    this.list.sortOrder = dir;
  }

  onSearch(event: any): void {
    if (event.keyCode === 13) {
      this.list.filter = this.searchText;
      this.list.get();
    }
  }
}
