import type {
  CreateUpdateGroupDto,
  CreateUpdateGroupMemberDto,
  GroupDto,
  GroupGetListInput,
  GroupMemberGetListInput,
  GroupMemberListDto,
  UserWithoutDetails,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  apiName = 'Default';

  addMember = (input: CreateUpdateGroupMemberDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GroupDto>(
      {
        method: 'POST',
        url: '/api/app/group/member',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  create = (input: CreateUpdateGroupDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GroupDto>(
      {
        method: 'POST',
        url: '/api/app/group',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/group/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GroupDto>(
      {
        method: 'GET',
        url: `/api/app/group/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GroupGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GroupDto>>(
      {
        method: 'GET',
        url: '/api/app/group',
        params: {
          name: input.name,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getMemberList = (input: GroupMemberGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GroupMemberListDto>>(
      {
        method: 'GET',
        url: '/api/app/group/member-list',
        params: {
          groupId: input.groupId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getNonMemberList = (id: number, search: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserWithoutDetails[]>(
      {
        method: 'GET',
        url: `/api/app/group/${id}/non-member-list`,
        params: { search },
      },
      { apiName: this.apiName, ...config },
    );

  removeMember = (input: CreateUpdateGroupMemberDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GroupDto>(
      {
        method: 'DELETE',
        url: '/api/app/group/member',
        params: { groupId: input.groupId, memberId: input.memberId },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateGroupDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GroupDto>(
      {
        method: 'PUT',
        url: `/api/app/group/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
