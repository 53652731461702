import { AuthService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoryAxis } from '@progress/kendo-angular-charts';
import { ProgressBarOrientation } from '@progress/kendo-angular-progressbar';
import { DashboardInput, FilterModel } from '@proxy/common';
import { DashboardService } from '@proxy/dashboards';
import { NgxSpinnerService } from 'ngx-spinner';

import { forkJoin } from 'rxjs';
import { FilterTypes } from 'src/core/enums/chart-filter';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  formGroup: FormGroup;

  selectedCustomValue: any;
  filterTypes = FilterTypes;
  filterTypeForCountCard;

  public formStatusData;
  public top5FormResponseData;
  public formViewOverTimeData;
  public dashboardDropoffFormViewData;

  public formViewOverTimeCategory: CategoryAxis = {
    categories: [],
  };

  public dashboardDropoffFormViewCategory: CategoryAxis = {
    categories: [],
  };

  orientation: ProgressBarOrientation = 'horizontal';

  lineGraphData = [123, 276, 310, 212, 240, 156, 98];
  public pregressBarData = [
    { name: 'Asia', data: 50 },
    { name: 'India', data: 10 },
    { name: 'USA', data: 98 },
    { name: 'Germany', data: 60 },
  ];

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated;
  }
  dashbordCount: any;
  isDashbordCount = false;
  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
  ) {
    if (
      this.authService.isAuthenticated &&
      localStorage.getItem('isPrivateFormURL') !== null &&
      localStorage.getItem('isPrivateFormURL') !== undefined
    ) {
      const redirectURL = localStorage.getItem('isPrivateFormURL');
      localStorage.removeItem('isPrivateFormURL');
      if (
        localStorage.getItem('isPrivateRender') === null ||
        localStorage.getItem('isPrivateRender') === undefined
      ) {
        localStorage.setItem('isPrivateRender', JSON.stringify(true));
        this.router.navigateByUrl(redirectURL);
      }
    } else if (!this.authService.isAuthenticated) {
      this.router.navigate(['account']);
    }
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      selectedCustomValue: [null],
    });

    if (this.authService.isAuthenticated) {
      this.selectedCustomValue = FilterTypes['This Month'];
      this.filterTypeForCountCard = FilterTypes[FilterTypes['This Month']];

      this.getDashboardOverView();
      this.getDashboardChartDetails();
    }
  }

  getDashboardOverView(): void {
    this.spinner.show();
    let data = this.setApiData();
    this.dashboardService.postOverview(data).subscribe(response => {
      this.spinner.hide();
      this.isDashbordCount = true;
      this.dashbordCount = response;
    });
  }

  login() {
    this.authService.navigateToLogin();
  }

  users: { name: string; title: string }[] = [
    { name: 'Carla Espinosa', title: 'Nurse' },
    { name: 'Bob Kelso', title: 'Doctor of Medicine' },
    { name: 'Janitor', title: 'Janitor' },
    { name: 'Perry Cox', title: 'Doctor of Medicine' },
    { name: 'Ben Sullivan', title: 'Carpenter and photographer' },
  ];

  onCustomChange() {
    this.filterTypeForCountCard = FilterTypes[this.selectedCustomValue];
    this.getDashboardChartDetails();
  }

  getDashboardChartDetails() {
    this.spinner.show();
    this.formStatusData = [];
    this.top5FormResponseData = [];
    this.formViewOverTimeData = [];
    this.dashboardDropoffFormViewData = [];
    let data = this.setApiData();

    const postDashboardFormStatus = this.dashboardService.postDashboardFormStatus(data);
    const postDashboardTop5ByResponse = this.dashboardService.postDashboardTop5ByResponse(data);
    const postDashboardFormViewOverTime = this.dashboardService.postDashboardFormViewOverTime(data);
    const postDashboardDropoffFormview = this.dashboardService.postDashboardDropoffFormview(data);

    forkJoin({
      formStatus: postDashboardFormStatus,
      top5formResponse: postDashboardTop5ByResponse,
      formViewOverTime: postDashboardFormViewOverTime,
      dashboardDropoffFormView: postDashboardDropoffFormview,
    }).subscribe({
      next: results => {
        this.spinner.hide();
        if (results) {
          this.formStatusData = results.formStatus.series.every(x => x.data === 0)
            ? []
            : results.formStatus.series;
          this.top5FormResponseData = results.top5formResponse.series.every(x => x.data === 0)
            ? []
            : results.top5formResponse.series;
          this.formViewOverTimeData = results.formViewOverTime.series[0].data.every(x => x === 0)
            ? []
            : results.formViewOverTime.series[0].data;
          this.formViewOverTimeCategory.categories = results.formViewOverTime.categories;
          this.dashboardDropoffFormViewData = results.dashboardDropoffFormView.series.every(x =>
            x.data.every(x => x === 0),
          )
            ? []
            : results.dashboardDropoffFormView.series;
          this.dashboardDropoffFormViewCategory.categories =
            results.dashboardDropoffFormView.categories;
        }
      },
      error: error => {},
    });
  }

  setApiData(): DashboardInput {
    let filterData: FilterModel = {
      filter: this.selectedCustomValue,
    };
    let apiInputData: DashboardInput = {
      dateFilter: filterData,
    };

    return apiInputData;
  }
}
