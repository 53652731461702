<!-- <div *ngIf="!this.authService.isAuthenticated && !isReadOnly && !isModalOpen">
  <div class="head-cont">
    <div class="logo-container">
      <div class="d-flex align-items-center" (click)="navigateToDashboard()">
        <img class="logo" src="./assets/images/logo/logo-white.png" />
        <span class="logo-name">Forms</span>
      </div>
    </div>
  </div>
</div> -->

<div *ngIf="!isModalOpen">
  <div class="q-content">
    <div class="form-render-conainter">
      <div class="q-content-header">
        <div class="form-header-content d-flex gap-10">
          <div class="form-icon"><i class="fal fa-newspaper"></i></div>
          <div>
            <h3 class="form-title">{{ formData?.title }}</h3>
            <p class="form-description mb-10">{{ formData?.description }}</p>
            <div [innerHTML]="formData?.content"></div>
          </div>
        </div>
      </div>
      <div class="form-content-form-builder">
        <formio
          *ngIf="isRenderForm"
          #FormioComponent
          [form]="displayForm"
          [submission]="submission"
          [readOnly]="isReadOnly"
          [options]="options"
          (submit)="onSubmit($event)"
          (change)="onFormChange($event)"
        >
        </formio>
        <div *ngIf="!isReadOnly && !isModalOpen" class="captcha">
          <ngx-recaptcha2
            #captchaElem
            siteKey="{{ siteKey }}"
            (success)="handleSuccess($event)"
            [size]="size"
            [hl]="lang"
            [theme]="theme"
            [type]="type"
          >
          </ngx-recaptcha2>
        </div>
      </div>
      <div class="button-container">
        <div *ngIf="!isReadOnly && !isModalOpen" [ngClass]="{ 'curser-not-allowd': !isCaptcha }">
          <button [disabled]="!isCaptcha" type="button" kendoButton (click)="saveForm()">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
