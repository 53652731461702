import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullString',
})
export class NullStringPipe implements PipeTransform {
  transform(value: any, defaultText: string = '-'): any {
    if (typeof value === undefined || value === null || value === '') {
      return defaultText;
    }

    return value;
  }
}
