import { AuthService } from '@abp/ng.core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SVGIcon, twitterBoxIcon } from '@progress/kendo-svg-icons';
import { HeaderDataEnum } from 'src/app/shared/enum/header-data-enum';

@Component({
  selector: 'app-top-header-navigation',
  templateUrl: './top-header-navigation.component.html',
  styleUrls: ['./top-header-navigation.component.scss'],
})
export class TopHeaderNavigationComponent {
  public headerDataList: Array<{ text: string; value: number }> = [
    { text: HeaderDataEnum[HeaderDataEnum.Setting], value: HeaderDataEnum.Setting },
    { text: HeaderDataEnum[HeaderDataEnum['Log out']], value: HeaderDataEnum['Log out'] },
  ];

  public defaultItem = {
    text: 'Admin',
    value: 1,
  };

  public twitterBoxIcon: SVGIcon = twitterBoxIcon;

  constructor(
    public authService: AuthService,
    private router: Router,
  ) {}

  onHeaderClick(event) {
    switch (event.value) {
      case HeaderDataEnum.Setting:
        this.router.navigateByUrl('account/manage');
        break;
      case HeaderDataEnum['Log out']:
        this.authService.logout();
        break;
      default:
        break;
    }
  }
}
