import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toaster, ToasterService } from '@abp/ng.theme.shared';

@Injectable()
export class CommonService {
  public themeColorChange = new Subject<any>();
  public editChange = new Subject<any>();
  public permissionChange = new Subject<any>();
  public deleteChange = new Subject<any>();

  toasterMessageConfiguration: Partial<Toaster.ToastOptions> = {
    life: 1500,
    tapToDismiss: true,
  };

  constructor(private toaster: ToasterService) {}

  astricValidationColor(cdr?, elementRef?, renderer?) {
    cdr.detectChanges();
    const labelElements = elementRef.nativeElement.querySelectorAll(
      'abp-extensible-form .form-label, abp-extensible-form .form-check-label',
    );
    labelElements.forEach((label: HTMLElement) => {
      const labelText = label.textContent.trim();
      if (label.textContent.includes('*')) {
        const labelTextWithoutAsterisk = labelText.endsWith('*') ? labelText.slice(0, -1) : '';
        label.textContent = labelTextWithoutAsterisk;
        const asterisk = renderer.createElement('span');
        asterisk.textContent = '*';
        renderer.setStyle(asterisk, 'color', '#f44336');
        renderer.appendChild(label, asterisk);
      }
    });
  }
}
