import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService, ResponseService } from '@proxy/forms';
import { CreateUpdateFormDto } from '@proxy/forms/dtos';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-render-admin',
  templateUrl: './form-render-admin.component.html',
  styleUrls: ['./form-render-admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormRenderAdminComponent implements OnInit {
  formData: CreateUpdateFormDto;
  subscriptionRouting: Subscription;
  @Input() formId = 0;
  displayForm: any;
  options = {};
  userEmail: any;
  constructor(
    private router: Router,
    private formService: FormService,
    private _Activatedroute: ActivatedRoute,
    private responseService: ResponseService,
    private config: ConfigStateService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.viewForm();
  }

  viewForm(): void {
    this.formService.get(this.formId).subscribe(res => {
      this.formData = res;
      this.displayForm = JSON.parse(res.schemaJson);
      this.options = {
        readOnly: false,
        hooks: {
          beforeRender: (instance: any, cb: any) => {
            if (instance && instance.submission && instance.submission.data.dataGrid) {
              const dataGrid = instance.submission.data.dataGrid;
              dataGrid.forEach((item: any, index: any) => {
                item.serialNumber = index + 1;
              });
            }
            cb();
          },
        },
      };
    });
  }
}
