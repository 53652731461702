import { Environment } from '@abp/ng.core';
export { QMenuComponent, QThemeLibModule } from '@dev/qtheme-lib';

const baseUrl = 'https://{0}.qforms.mysandboxsite.com';

export const environment = {
  production: false,
  recaptchaKey: '6Ld2InAnAAAAAO8YMgt5DLthgtyOqYS82XkCRzLu',
  application: {
    baseUrl,
    name: 'QForms',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://{0}.api.mysandboxsite.com/',
    redirectUri: baseUrl,
    clientId: 'QForms_App',
    responseType: 'code',
    scope: 'offline_access QForms',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://{0}.api.mysandboxsite.com',
      rootNamespace: 'QForms',
    },
  },
} as Environment;
