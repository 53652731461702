import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule, ListService } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeLeptonXModule } from '@abp/ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@abp/ng.theme.lepton-x/layouts';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule, forwardRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { FormioModule } from '@formio/angular';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ApiInterceptor } from '@abp/ng.core';
import { QFormsApiInterceptor } from 'src/core/lib/interceptors/api.interceptor';

import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AddGroupMemberPopupComponent,
  AdminSurveyChartComponent,
  AdminViewComponent,
  CreateGroupComponent,
  CreateNewCustomerComponent,
  CustomerListComponent,
  EmailComponent,
  FormBuilderComponent,
  FormListComponent,
  FormPreviewComponent,
  FormRenderAdminComponent,
  FormRenderComponent,
  GroupListComponent,
  SendFormComponent,
  SuccessResponseComponent,
} from './modules';
import { NgbdSortableHeader } from 'src/core/directive/sortable-directive';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ClipboardModule } from 'ngx-clipboard';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { PublishStatePipe } from './pipes/publish-state-pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UiExtensionsModule } from '@abp/ng.theme.shared/extensions';
import { PermissionManagementModule } from '@abp/ng.permission-management';
import { SharedModule } from './shared/shared.module';
import {
  CustomAddTenantComponent,
  CustomEmailComponent,
  CustomExtensibleTableComponent,
  CustomManageFeatureComponent,
  CustomPermissionComponent,
  CustomRolesComponent,
  CustomSettingComponent,
  CustomSettingFeaturesComponent,
  CustomUserComponent,
  CustomeTenantComponent,
} from './shared';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbTabsetModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { CommonService } from 'src/core/services/common.service';
import { NbDialogRef } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NullStringPipe } from './pipes/null-string.pipe';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DrawerModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { FilterModule } from '@progress/kendo-angular-filter';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { PagerModule } from '@progress/kendo-angular-pager';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IntlModule } from '@progress/kendo-angular-intl';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { RightSidebarComponent } from './modules/right-sidebar/right-sidebar.component';
import { TopHeaderNavigationComponent } from './modules/top-header-navigation/top-header-navigation.component';
import { NbModule } from './nebula/nb.module';
import { UseEnumValuePipe } from './pipes/use-enum-value.pipe';
@NgModule({
  imports: [
    NgSelectModule,
    BrowserModule,
    SharedModule,
    UiExtensionsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbTooltipModule,
    NgxCaptchaModule,
    ClipboardModule,
    MdbTabsModule,
    FormsModule,
    PermissionManagementModule,
    AutocompleteLibModule,
    NbTabsetModule,
    NgApexchartsModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    FeatureManagementModule.forRoot(),
    NbModule.forRoot(),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NgxSpinnerModule,
    ButtonsModule,
    LayoutModule,
    DrawerModule,
    InputsModule,
    LabelModule,
    IconsModule,
    DropDownsModule,
    TreeViewModule,
    TreeListModule,
    GridModule,
    FilterModule,
    SwitchModule,
    MenuModule,
    PopupModule,
    DialogsModule,
    TreeViewModule,
    UploadsModule,
    ListViewModule,
    EditorModule,
    DateInputsModule,
    IntlModule,
    ScrollViewModule,
    DropDownsModule,
    PagerModule,
    IndicatorsModule,
    ChartsModule,
    ProgressBarModule,
    TooltipsModule,
    ProgressBarModule,
  ],
  declarations: [
    AppComponent,
    FormBuilderComponent,
    CreateNewCustomerComponent,
    CustomerListComponent,
    FormListComponent,
    FormRenderComponent,
    NgbdSortableHeader,
    AdminViewComponent,
    FormRenderAdminComponent,
    SuccessResponseComponent,
    SendFormComponent,
    PublishStatePipe,
    UseEnumValuePipe,
    AdminSurveyChartComponent,
    CustomRolesComponent,
    CustomExtensibleTableComponent,
    CustomUserComponent,
    CustomeTenantComponent,
    GroupListComponent,
    CreateGroupComponent,
    AddGroupMemberPopupComponent,
    CustomPermissionComponent,
    CustomManageFeatureComponent,
    EmailComponent,
    CustomAddTenantComponent,
    NullStringPipe,
    CustomSettingComponent,
    CustomSettingFeaturesComponent,
    CustomEmailComponent,
    FormPreviewComponent,
    DashboardComponent,
    RightSidebarComponent,
    TopHeaderNavigationComponent,
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: ApiInterceptor,
      useClass: QFormsApiInterceptor,
    },
    ListService,
    NgbActiveModal,
    CommonService,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    {
      provide: NbDialogRef,
      useValue: {
        close: (dialogResult: any) => {},
      },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SendFormComponent),
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
