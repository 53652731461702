import type {
  CreateUpdateFormRevisionDto,
  FormDto,
  FormRevisionDto,
  FormRevisionGetListInput,
} from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormRevisionService {
  apiName = 'Default';

  create = (input: CreateUpdateFormRevisionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormRevisionDto>(
      {
        method: 'POST',
        url: '/api/app/form-revision',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/form-revision/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormRevisionDto>(
      {
        method: 'GET',
        url: `/api/app/form-revision/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: FormRevisionGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<FormRevisionDto>>(
      {
        method: 'GET',
        url: '/api/app/form-revision',
        params: {
          formId: input.formId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  rollback = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormDto>(
      {
        method: 'POST',
        url: `/api/app/form-revision/${id}/rollback`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateFormRevisionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormRevisionDto>(
      {
        method: 'PUT',
        url: `/api/app/form-revision/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
