<kendo-dialog [width]="450">
  <div class="response-dialog">
    <div
      class="response-conatiner d-flex justify-content-center align-items-center flex-column gap-10"
    >
      <img src="../../../../assets/images/success-toast.png" alt="success" />
      <p class="mb-0 success-message">Response save successfully!!!</p>
    </div>
  </div>
</kendo-dialog>
