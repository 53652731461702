import { mapEnumToOptions } from '@abp/ng.core';

export enum PubslishState {
  Draft = 0,
  Private = 1,
  Public = 2,
  Anonymous = 3,
  Closed = 4,
}

export const pubslishStateOptions = mapEnumToOptions(PubslishState);
