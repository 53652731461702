import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import {
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbCardModule,
  NbThemeModule,
  NbListModule,
  NbInputModule,
  NbCheckboxModule,
  NbRadioModule,
  NbDatepickerModule,
  NbAccordionModule,
} from '@nebular/theme';
import { FormsModule as ngFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbListModule,
  NbInputModule,
  NbCheckboxModule,
  NbRadioModule,
  NbDatepickerModule,
  ngFormsModule,
  NbAccordionModule,
  NgxDatatableModule,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...NB_MODULES],
  declarations: [],
})
export class NbModule {
  static forRoot(): ModuleWithProviders<NbModule> {
    return {
      ngModule: NbModule,
      providers: [
        ...NbThemeModule.forRoot({
          name: 'default',
        }).providers,
      ],
    };
  }
}
