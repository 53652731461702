import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { collapse, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import {
  EmailSettingsDto,
  EmailSettingsService,
  SettingManagementPolicyNames,
} from '@abp/ng.setting-management/config';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { CommonService } from 'src/core/services/common.service';

@Component({
  selector: 'app-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss'],
})
export class CustomEmailComponent implements OnInit {
  form!: UntypedFormGroup;
  emailTestForm: UntypedFormGroup;
  saving = false;
  emailingPolicy = SettingManagementPolicyNames.Emailing;
  isEmailTestModalOpen = false;
  modalSize: NgbModalOptions = { size: 'lg' };

  @ViewChild('dialog') dialog: any;
  dialogRef: NbDialogRef<any>;
  isAddEditOpened = false;
  constructor(
    private emailSettingsService: EmailSettingsService,
    private fb: UntypedFormBuilder,
    private toasterService: ToasterService,
    private dialogService: NbDialogService,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    this.getData();
  }

  open(): void {
    this.isAddEditOpened = true;
  }

  public close(status: string): void {
    this.isAddEditOpened = false;
  }

  private getData() {
    this.emailSettingsService.get().subscribe(res => {
      this.buildForm(res);
    });
  }

  private buildForm(emailSettings: EmailSettingsDto) {
    this.form = this.fb.group({
      defaultFromDisplayName: [emailSettings.defaultFromDisplayName, [Validators.required]],
      defaultFromAddress: [emailSettings.defaultFromAddress, [Validators.required]],
      smtpHost: [emailSettings.smtpHost],
      smtpPort: [emailSettings.smtpPort, [Validators.required]],
      smtpEnableSsl: [emailSettings.smtpEnableSsl],
      smtpUseDefaultCredentials: [emailSettings.smtpUseDefaultCredentials],
      smtpDomain: [emailSettings.smtpDomain],
      smtpUserName: [emailSettings.smtpUserName],
      smtpPassword: [emailSettings.smtpPassword],
    });
  }

  submit() {
    if (this.saving || this.form.invalid) return;

    this.saving = true;
    this.emailSettingsService
      .update(this.form.value)
      .pipe(finalize(() => (this.saving = false)))
      .subscribe(() => {
        this.toasterService.success(
          'AbpSettingManagement::SuccessfullySaved',
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.getData();
      });
  }

  openSendEmailModal() {
    this.buildEmailTestForm();
    this.isEmailTestModalOpen = true;
    this.open();
  }

  buildEmailTestForm(): void {
    this.emailTestForm = this.fb.group({
      senderEmailAddress: ['', [Validators.required, Validators.email]],
      targetEmailAddress: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      body: [''],
    });
  }

  emailTestFormSubmit() {
    if (this.emailTestForm.invalid) {
      return;
    }
    this.emailSettingsService.sendTestEmail(this.emailTestForm.value).subscribe(res => {
      this.toasterService.success(
        'AbpSettingManagement::SuccessfullySent',
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.isEmailTestModalOpen = false;
      this.isAddEditOpened = false;
    });
  }
}
