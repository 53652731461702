<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>
    {{ headerText }}
  </div>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="groupForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Group Name"><span class="required">*</span></kendo-label>
      <kendo-textbox formControlName="name" placeholder="Group Name" #groupName></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="assignUser" text="Assign User"> </kendo-label>
      <kendo-multiselect
        #assignUser
        [clearButton]="false"
        [filterable]="true"
        [checkboxes]="true"
        [data]="listItemData"
        textField="text"
        valueField="value"
        [ngModelOptions]="{ standalone: true }"
        formControlName="assignUser"
        (filterChange)="onProjectSearch($event)"
      ></kendo-multiselect>
    </kendo-formfield>
  </form>
</div>

<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
  <div [ngClass]="{ 'curser-not-allowd': groupForm.invalid }" class="display-inline flex-0">
    <button kendoButton (click)="onSubmit()" [disabled]="groupForm.invalid">{{ title }}</button>
  </div>
</kendo-dialog-actions>
