import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-response',
  templateUrl: './success-response.component.html',
  styleUrls: ['./success-response.component.scss'],
})
export class SuccessResponseComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['account']);
    }, 7000);
  }
}
