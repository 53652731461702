import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortDirection } from '../directive/sortable-directive';

interface SearchResult {
  customerList: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1: any, v2: any) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(customer: any[], column: string, direction: string): any[] {
  if (direction === '') {
    return customer;
  } else {
    return [...customer].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(customer: any, term: string, pipe: PipeTransform) {
  return Object.values(customer).some((val: any) => val.toString().toLowerCase().includes(term));
}

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _customerList$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 4,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(private pipe: DecimalPipe) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result: any) => {
        this._customerList$.next(result.customer);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get customerList$() {
    return this._customerList$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): any {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    let CUSTOMER = [];
    if (
      localStorage.getItem('customerList') !== null &&
      localStorage.getItem('customerList') !== undefined
    ) {
      const list: any = localStorage.getItem('customerList');
      CUSTOMER = JSON.parse(list);
    }
    // 1. sort
    let customer = sort(CUSTOMER, sortColumn, sortDirection);

    // 2. filter
    customer = customer.filter(customer => matches(customer, searchTerm, this.pipe));
    const total = customer.length;

    // 3. paginate
    customer = customer.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ customer, total });
  }
}
