import type { ColumnChartDetailModel, DashboardCardModel } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { BarChartDetailModel, DashboardInput, PieChartDetailModel } from '../common/models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiName = 'Default';

  postDashboardDropoffFormview = (filter: DashboardInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BarChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/dashboard-dropoff-formview',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postDashboardFormStatus = (filter: DashboardInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/dashboard-form-status',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postDashboardFormViewOverTime = (filter: DashboardInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ColumnChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/dashboard-form-view-over-time',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postDashboardTop5ByResponse = (filter: DashboardInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PieChartDetailModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/dashboard-top5By-response',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  postOverview = (filter: DashboardInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashboardCardModel>(
      {
        method: 'POST',
        url: '/api/app/dashboard/overview',
        body: filter,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
