import type { CreateUpdateFormDto, FormDto, FormGetListInput, SendEmailDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  apiName = 'Default';

  create = (input: CreateUpdateFormDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormDto>(
      {
        method: 'POST',
        url: '/api/app/form',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/form/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormDto>(
      {
        method: 'GET',
        url: `/api/app/form/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: FormGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<FormDto>>(
      {
        method: 'GET',
        url: '/api/app/form',
        params: {
          title: input.title,
          description: input.description,
          content: input.content,
          displayKey: input.displayKey,
          schemaJson: input.schemaJson,
          publishState: input.publishState,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getSchemaJsonByDisplaykey = (
    displayKey: string,
    userEmail: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, FormDto>(
      {
        method: 'GET',
        url: `/api/app/Form/schema-json-by-displaykey/${displayKey}`,
        params: { userEmail },
      },
      { apiName: this.apiName, ...config },
    );

  sendEmail = (sendEmailDto: SendEmailDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: '/api/app/form/send-email',
        body: sendEmailDto,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateFormDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormDto>(
      {
        method: 'PUT',
        url: `/api/app/form/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
