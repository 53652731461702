import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      {
        path: '/',
        name: '::Menu:Dashboard',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '/form-List',
        name: '::Forms',
        iconClass: 'fas fa-list',
        order: 2,
        requiredPolicy: 'QForms.Form',
        layout: eLayoutType.application,
      },
      {
        path: '/group-List',
        name: '::Groups',
        iconClass: 'fas fa-users',
        order: 3,
        requiredPolicy: 'AbpIdentity.Roles',
        layout: eLayoutType.application,
      },
    ]);
  };
}
