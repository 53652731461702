<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-bg grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="gridView"
      kendoGridSelectBy="id"
      [pageSize]="20"
      [pageable]="true"
      [height]="500"
    >
      <kendo-grid-column field="userName" title="User Name" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.userName }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="emailAddress" title="Email Address" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.emailAddress }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="creationTime" title="Response Submitted" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.creationTime }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="actions"
        title=""
        [width]="110"
        [resizable]="false"
        filter="numeric"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <ul class="grid-action-list">
            <div class="d-flex gap-10">
              <li>
                <a title="View" (click)="view(dataItem)">
                  <i class="far fa-eye"></i>
                </a>
              </li>
            </div>
          </ul>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
