import { AuthService, ListService } from '@abp/ng.core';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupService } from '@proxy/forms';
import { CreateGroupComponent } from './create-group/create-group.component';
import { CommonService } from 'src/core/services/common.service';
import { NotificationTextMessage } from 'src/core/enums/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';

import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { ActionMenuEnum } from 'src/app/shared/enum/action-menu.enum';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  isMobile: boolean = false;
  isListView = true;

  gridHeight: number;

  originalGroupList: any[];
  groupList: any[];

  public actionMenu = [
    {
      text: ActionMenuEnum.Edit,
      iconClass: 'far fa-pen',
    },
    {
      text: ActionMenuEnum.Delete,
      iconClass: 'far fa-trash-alt',
    },
    {
      text: ActionMenuEnum.Add,
      iconClass: 'far fa-plus',
    },
  ];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  searchText = '';

  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 20;
  public skip = 0;
  sorting = null;
  public multiple = false;
  public allowUnsort = true;
  //#endregion

  constructor(
    private router: Router,
    public readonly list: ListService,
    private confirmation: ConfirmationService,
    private groupService: GroupService,
    private commonService: CommonService,
    public authService: AuthService,
    private dialogService: DialogService,
    private toasterService: ToasterService,
  ) {}

  ngOnInit() {
    this.getList();

    this.checkScreenSize();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.checkScreenSize();
      this.calculateGridHeight();
    });
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 450;
    this.isListView = !this.isMobile;
  }

  getList(): void {
    const groupStreamCreator = query => this.groupService.getList(query);
    this.list.hookToQuery(groupStreamCreator).subscribe(response => {
      this.groupList = response.items;
      this.originalGroupList = response.items;

      this.loadItems();
    });
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.loadItems();
  }

  public loadItems(): void {
    this.gridView = {
      data: this.originalGroupList.slice(this.skip, this.skip + this.pageSize),
      total: this.originalGroupList.length,
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData(): void {
    this.gridView = {
      data: orderBy(this.groupList, this.sort),
      total: this.groupList.length,
    };
  }

  onGroupAdd(id: any): void {
    const dialogRef = this.dialogService.open({
      content: CreateGroupComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as CreateGroupComponent;
    attachFileInfo.groupId = id;
    attachFileInfo.setOnLoadData();
    dialogRef.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.getList();
      }
    });
  }

  onSearch(event: any): void {
    const filteredListData = process(
      this.originalGroupList.slice(this.skip, this.skip + this.pageSize),
      {
        filter: {
          logic: 'or',
          filters: [{ field: 'name', operator: 'contains', value: this.searchText }],
        },
      },
    );

    const filteredGridData = process(this.originalGroupList, {
      filter: {
        logic: 'or',
        filters: [{ field: 'name', operator: 'contains', value: this.searchText }],
      },
    });

    this.gridView.data = filteredListData.data;
    this.groupList = filteredGridData.data;
  }

  addUser(data: any): void {
    var params = {
      id: btoa(data.id),
    };
    this.router.navigate(['add-group-user', params]);
  }

  delete(id: any): void {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this form?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        this.groupService.delete(id).subscribe(res => {
          this.toasterService.success(
            NotificationTextMessage.groupDeletedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getList();
        });
      }
    });
  }

  showListView(isListView): void {
    this.isListView = isListView;
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getList();
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 84;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  onSelectAction(event, data) {
    const selectedAction = event.text;

    switch (selectedAction) {
      case ActionMenuEnum.Edit:
        this.onGroupAdd(data.id);
        break;
      case ActionMenuEnum.Delete:
        this.delete(data.id);
        break;

      case ActionMenuEnum.Add:
        this.addUser(data);
        break;
    }
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.getList();
    }
  }
}
