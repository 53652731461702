import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PreviewType } from 'src/core/enums/preview-type';

@Component({
  selector: 'app-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.scss'],
})
export class FormPreviewComponent implements OnInit {
  formId: any;
  subscriptionRouting: Subscription;
  selectedTabIndex = 0;
  previewTypeEnum = PreviewType;
  constructor(private _Activatedroute: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.formId = +atob(params.get('id')!);
        const previewType = +atob(params.get('previewTypeId')!);
        if (previewType === PreviewType.Preview) {
          this.selectedTabIndex = 0;
        } else if (previewType === PreviewType.Response) {
          this.selectedTabIndex = 1;
        } else if (previewType === PreviewType.Report) {
          this.selectedTabIndex = 2;
        }
      }
    });
  }

  onTabSelect(event: any) {
    this.selectedTabIndex = event.index;
  }
}
