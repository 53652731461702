<div *ngIf="this.authService.isAuthenticated">
  <div class="form-render-conainter">
    <div class="q-content-header">
      <div class="form-header-content d-flex gap-10">
        <div class="form-icon"><i class="fal fa-newspaper"></i></div>
        <div>
          <h3 class="form-title">{{ formData?.title }}</h3>
          <p class="form-description mb-10">{{ formData?.description }}</p>
          <div [innerHTML]="formData?.content"></div>
        </div>
      </div>
    </div>
    <div class="form-content-form-builder">
      <formio [form]="displayForm" [options]="options" [readOnly]="true"> </formio>
    </div>
  </div>
</div>
