import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '@proxy/forms';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexTooltip,
} from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Renderer2, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { ToasterService } from '@abp/ng.theme.shared';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  colors: string[];
  title: ApexTitleSubtitle;
  subtitle: ApexTitleSubtitle;
  fullTitle: string;
};

@Component({
  selector: 'app-admin-survey-chart',
  templateUrl: './admin-survey-chart.component.html',
  styleUrls: ['./admin-survey-chart.component.scss'],
})
export class AdminSurveyChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: any[] = [];
  dataList: any[] = [];
  public fullTitle: string = '';
  private chartWidth: number;
  private needsTruncation: boolean = true;
  public chartRawData: any[] = [];

  subscriptionRouting: Subscription;
  @Input() formId: any;
  constructor(
    private formService: FormService,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.renderer.listen('window', 'resize', () => {
      this.updateChartWidth();
      this.processRawChartData();
      this.cdr.detectChanges();
    });
    this.getChartData(this.formId);
    this.updateChartWidth();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.updateChartWidth();
      this.processRawChartData();

      if (this.chartWidth > 0) {
        this.processRawChartData();

        this.needsTruncation = false;

        this.cdr.detectChanges();
      }
    }, 500);
  }

  updateChartWidth() {
    const chartElement = this.el.nativeElement.querySelector('.admin-survey');
    this.chartWidth = chartElement ? chartElement.offsetWidth : 0;
  }

  truncateTitle = (text: string): string => {
    const avgPixelsPerChar = 11;
    const maxLength = (this.chartWidth || 0) / avgPixelsPerChar;
    return text.length <= maxLength ? text : text.slice(0, maxLength) + '...';
  };

  getChartData(id): void {
    this.formService.getSurvey(id).subscribe(
      res => {
        if (res) {
          this.chartRawData = res;
          this.processRawChartData();
        }
      },
      err => {
        this.toasterService.error(err.error.error.details);
      },
    );
  }

  processRawChartData(): void {
    this.chartOptions = [];
    this.chartRawData.forEach(element => {
      this.setChartData(element);
    });
  }

  setChartData(res: any): void {
    let letters = '0123456789ABCDEF';
    let colourList = [];
    for (var j = 0; j < res.data; j++) {
      let color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      colourList.push(color);
    }

    let fullTitle = this.chartOptions.length + 1 + ') ' + res.textLabel;

    let chartOptions = {
      fullTitle: this.chartOptions.length + 1 + ') ' + res.textLabel,
      series: [
        {
          data: res.data,
        },
      ],
      chart: {
        type: 'bar',
        height: 200,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
        },
      },
      colors: colourList,
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#000'],
          fontWeight: 400,
          fontSize: '12px',
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        categories: res.categories,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: this.truncateTitle(fullTitle),
        align: 'left',
        floating: true,
        style: {
          color: ['black'],
          fontSize: '16px',
          fontWeight: 500,
          margin: '150px 0 0 0',
        },
      },
      tooltip: {
        colors: ['red'],
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
        },
      },
    };
    this.chartOptions.push(chartOptions);
  }
}
