import { ListService, PagedResultDto } from '@abp/ng.core';
import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseService } from '@proxy/forms';
import { FormDto, ResponseDto, ResponseGetListInput } from '@proxy/forms/dtos';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/core/directive/sortable-directive';
import { CustomerService } from 'src/core/services/customer.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  providers: [CustomerService, DecimalPipe],
})
export class CustomerListComponent implements OnInit {
  customerList$: Observable<any>;
  total$: Observable<any>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    private router: Router,
    public service: CustomerService,
    private responseService: ResponseService,
    public readonly list: ListService,
  ) {
    this.customerList$ = service.customerList$;
    this.total$ = service.total$;
  }

  book: ResponseGetListInput;

  formDataDisplay = { items: [], totalCount: 0 } as PagedResultDto<ResponseDto>;

  selectedBook = {} as FormDto;
  ngOnInit(): void {
    const bookStreamCreator = query => this.responseService.getList(query);
    this.list.hookToQuery(bookStreamCreator).subscribe(response => {
      this.formDataDisplay = response;
    });
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  addCustomer(): void {
    this.router.navigateByUrl('createCustomer');
  }
}
