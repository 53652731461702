<div class="q-content">
  <div *ngIf="this.authService.isAuthenticated" class="q-content-bg user-group">
    <div class="q-content-header mb-20">
      <form class="k-form k-form-md">
        <fieldset class="k-form-fieldset">
          <div class="d-flex gap-20 mb-24">
            <kendo-combobox
              [allowCustom]="true"
              [data]="userList"
              [textField]="'text'"
              [valueField]="'value'"
              [filterable]="true"
              (filterChange)="getUserList($event)"
              [valueNormalizer]="valueNormalizer"
              [(value)]="selectedUser"
              (valueChange)="onValueChange($event)"
              (selectionChange)="onValueChange($event)"
              class="w-350"
            >
            </kendo-combobox>

            <div [ngClass]="{ 'curser-not-allowd': !selectedUser || selectedUser.length === 0 }">
              <button
                kendoButton
                [disabled]="!selectedUser || selectedUser.length === 0"
                type="submit"
                (click)="saveMember()"
              >
                Submit
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div>
      <h5 class="title">User's of the group</h5>
    </div>
    <div class="grid-wrapper">
      <kendo-grid
        #grid
        [data]="gridView"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="
          groupList === undefinded
            ? false
            : {
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: pagerposition
              }
        "
        [height]="gridHeight"
        (pageChange)="pageChange($event)"
        [sortable]="{
          allowUnsort: true,
          mode: multiple ? 'multiple' : 'single'
        }"
        [sort]="sort"
        (sortChange)="sortChange($event)"
      >
        <kendo-grid-column field="userName" title="User Name" [resizable]="false" [width]="300">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ dataItem.userName }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email" title="Email" [resizable]="false" [width]="300">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{ dataItem.email }}</div>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="d-flex align-items-center justify-content-between">
              <div>{{ dataItem.name }}</div>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="action" title="" filter="numeric" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ul class="grid-action-list">
              <li>
                <a title="Delete" (click)="delete(dataItem)">
                  <i class="far fa-trash-alt"></i>
                </a>
              </li>
            </ul>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
