<div class="q-content">
  <div>
    <div class="d-flex-row">
      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col">
        <div class="d-flex-content mb-20">
          <div class="d-flex justify-content-between mb-20">
            <div>
              <p class="box-name">Form</p>
              <p class="box-amount">K</p>
            </div>
            <div class="box-logo1 box-logo">
              <i class="far fa-user"></i>
            </div>
          </div>
          <p class="box-data">0 in month</p>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col">
        <div class="d-flex-content mb-20">
          <div class="d-flex justify-content-between mb-20">
            <div>
              <p class="box-name">Group</p>
              <p class="box-amount">group</p>
            </div>
            <div class="box-logo2 box-logo">
              <i class="far fa-images"></i>
            </div>
          </div>
          <p class="box-data">0 in month</p>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col">
        <div class="d-flex-content mb-20">
          <div class="d-flex justify-content-between mb-20">
            <div>
              <p class="box-name">User</p>
              <p class="box-amount">K</p>
            </div>
            <div class="box-logo3 box-logo">
              <i class="far fa-thumbs-up"></i>
            </div>
          </div>
          <p class="box-data">0 in month</p>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col">
        <div class="d-flex-content mb-20">
          <div class="d-flex justify-content-between mb-20">
            <div>
              <p class="box-name">Response</p>
              <p class="box-amount">K</p>
            </div>
            <div class="box-logo4 box-logo">
              <i class="far fa-star"></i>
            </div>
          </div>
          <p class="box-data">0 in month</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-between gap-20 flex-lg-nowrap flex-sm-nowarp flex-wrap">
  <div *ngFor="let item of chartOptions" class="admin-survey">
    <apx-chart
      [series]="item.series"
      [chart]="item.chart"
      [dataLabels]="item.dataLabels"
      [stroke]="item.stroke"
      [colors]="item.colors"
      [title]="item.title"
      [subtitle]="item.subtitle"
      [plotOptions]="item.plotOptions"
      [yaxis]="item.yaxis"
      [xaxis]="item.xaxis"
      [tooltip]="item.tooltip"
      [ngbTooltip]="item.title.text !== item.fullTitle ? item.fullTitle : null"
      [autoClose]="true"
    >
      ></apx-chart
    >
  </div>
</div>
