<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div class="d-flex justify-content-between gap-20 mb-20">
      <kendo-textbox
        placeholder="Search for group name"
        class="search"
        (keyup.enter)="onSearch($event)"
        [(ngModel)]="searchText"
        (ngModelChange)="onSearchTextChange($event)"
        ><ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i> </ng-template
      ></kendo-textbox>
      <div class="d-flex gap-15">
        <button kendoButton id="create" type="button" [svgIcon]="plusIcon" (click)="onGroupAdd(0)">
          <span><i class="far fa-plus mr-10"></i>{{ '::New Group' | abpLocalization }}</span>
        </button>
        <div class="d-flex align-items-center q-toggle-button">
          <button
            [class.k-selected]="isListView"
            kendoButton
            fillMode="flat"
            (click)="showListView(true)"
          >
            <i class="fas fa-list-ul font-size-16"></i>
          </button>
          <button
            [class.k-selected]="!isListView"
            kendoButton
            fillMode="flat"
            (click)="showListView(false)"
          >
            <i class="fas fa-th-large font-size-16"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isListView" class="grid-wrapper q-content-bg">
    <kendo-grid
      #grid
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="
        groupList === undefinded
          ? false
          : {
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext,
              position: pagerposition
            }
      "
      [height]="gridHeight"
      (pageChange)="pageChange($event)"
      [sortable]="{
        allowUnsort: true,
        mode: multiple ? 'multiple' : 'single'
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
    >
      <kendo-grid-column field="name" title="Group Name">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div>{{ dataItem.name }}</div>
            <ul class="grid-action-list">
              <div class="d-flex gap-10">
                <li>
                  <a title="Edit" (click)="onGroupAdd(dataItem.id)">
                    <i class="far fa-pen"></i>
                  </a>
                </li>
                <li *ngIf="!dataItem.isDefault">
                  <a title="Delete" (click)="delete(dataItem.id)">
                    <i class="far fa-trash-alt"></i>
                  </a>
                </li>
                <li>
                  <a title="Add Users" (click)="addUser(dataItem)">
                    <i class="far fa-plus"></i>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div *ngIf="!isListView">
    <div class="d-flex-row">
      <div
        *ngFor="let project of groupList; let i = index"
        class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col mb-20"
      >
        <kendo-card class="wp-100 h-100">
          <kendo-card-header class="k-hstack">
            <div class="d-flex align-items-center justify-content-between wp-100">
              <h1 kendoCardTitle title="{{ project.name }}" class="clamp">
                {{ project.name }}
              </h1>
              <kendo-dropdownbutton
                [data]="actionMenu"
                class="dropdown-button-icon"
                (itemClick)="onSelectAction($event, project)"
              >
                <i class="fas fa-ellipsis"></i>
                <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                  <div *ngIf="dataItem.text === 'Edit'">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                  <div *ngIf="dataItem.text === 'Delete'">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                  <div *ngIf="dataItem.text === 'Add'">
                    <i [ngClass]="dataItem.iconClass"></i>
                    <span class="ml-10">{{ dataItem.text }}</span>
                  </div>
                </ng-template>
              </kendo-dropdownbutton>
            </div>
          </kendo-card-header>
          <kendo-card-body>
            <p kendoCardSubtitle class="fw-normal mb-5">Name:</p>
            <div class="height-clamp" title="{{ project.name }}">
              {{ project.name }}
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>
