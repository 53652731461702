import { mapEnumToOptions } from '@abp/ng.core';

export enum PublishState {
  Draft = 0,
  Private = 1,
  Public = 2,
  Anonymous = 3,
  Closed = 4,
  All = -1,
}

export const publishStateOptions = mapEnumToOptions(PublishState);
