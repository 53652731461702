<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div
      class="d-flex gap-15 align-items-center justify-content-start wp-100 mb-2 d-flex-sm-column"
    >
      <kendo-dropdownlist
        [data]="filterTypes | useEnumValues"
        placeholder="value"
        textField="value"
        valueField="key"
        [(ngModel)]="selectedCustomValue"
        [ngModelOptions]="{ standalone: true }"
        [value]="selectedCustomValue"
        [valuePrimitive]="true"
        (valueChange)="onCustomChange()"
        class="bg-white wp-100-sm w-260"
      ></kendo-dropdownlist>
    </div>
  </div>
  <div>
    <div class="d-flex-row">
      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ dashbordCount?.card1?.cardName }}</p>
              <p class="q-card-value m-0">{{ dashbordCount?.card1?.total >= 1000 ? (dashbordCount?.card1?.total / 1000) + 'K' : dashbordCount?.card1?.total }}</p>
            </div>
            <div class="q-card-icon q-card-icon-first">
              <i class="far fa-user"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">{{ dashbordCount?.card1?.increase }} {{ filterTypeForCountCard }}</p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ dashbordCount?.card2?.cardName }}</p>
              <p class="q-card-value m-0">{{ dashbordCount?.card2?.total }}</p>
            </div>
            <div class="q-card-icon q-card-icon-second">
              <i class="far fa-images"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">{{ dashbordCount?.card2?.increase }} {{ filterTypeForCountCard }}</p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ dashbordCount?.card3?.cardName }}</p>
              <p class="q-card-value m-0">{{ dashbordCount?.card3?.total >= 1000 ? (dashbordCount?.card3?.total / 1000) + 'K' : dashbordCount?.card3?.total }}</p>
            </div>
            <div class="q-card-icon q-card-icon-third">
              <i class="far fa-thumbs-up"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">{{ dashbordCount?.card3?.increase }} {{ filterTypeForCountCard }}</p>
          </div>
        </div>
      </div>

      <div class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col d-flex">
        <div class="q-card mb-20 flex-1">
          <div class="q-card-header d-flex justify-content-between">
            <div>
              <p class="q-card-title">{{ dashbordCount?.card4?.cardName }}</p>
              <p class="q-card-value m-0">{{ dashbordCount?.card4?.total >= 1000 ? (dashbordCount?.card4?.total / 1000) + 'K' : dashbordCount?.card4?.total }}</p>
            </div>
            <div class="q-card-icon q-card-icon-forth">
              <i class="far fa-star"></i>
            </div>
          </div>
          <div class="q-card-body pb-0">
            <p class="m-0">{{ dashbordCount?.card4?.increase }} {{ filterTypeForCountCard }}</p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="d-flex gap-20 mb-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Forms Status</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="formStatusData && formStatusData.length > 0; else noDataTemplateStatus"
            >
              <kendo-chart class="h-300">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    type="donut"
                    [data]="formStatusData"
                    categoryField="name"
                    field="data"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend [visible]="true"></kendo-chart-legend>
                <kendo-chart-tooltip>
                  <ng-template
                    kendoChartSeriesTooltipTemplate
                    let-value="value"
                    let-category="category"
                  >
                    {{ category }}:{{ value }}
                  </ng-template>
                </kendo-chart-tooltip>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateStatus>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon status d-flex justify-content-center align-items-center">
                  <i class="far fa-signal-bars"></i>
                </div>
                <p class="subscribers mb-0">0 Forms Status</p>
                <span class="description">There are no Forms Status available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Forms View Over Time</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="
                formViewOverTimeData && formViewOverTimeData.length > 0;
                else noDataTemplateGrowth
              "
            >
              <kendo-chart class="h-300" [categoryAxis]="formViewOverTimeCategory">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    type="line"
                    [data]="formViewOverTimeData"
                    [color]="'#003BBE'"
                  >
                  </kendo-chart-series-item>
                  <kendo-chart-tooltip>
                    <ng-template
                      kendoChartSeriesTooltipTemplate
                      let-value="value"
                      let-category="category"
                    >
                      {{ category }}:{{ value }}
                    </ng-template>
                  </kendo-chart-tooltip>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateGrowth>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon growth d-flex justify-content-center align-items-center">
                  <i class="far fa-arrow-up-right-dots"></i>
                </div>
                <p class="subscribers mb-0">0 Forms View Over Time</p>
                <span class="description">There are no Forms View Over Time available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>

      <div class="d-flex gap-20 d-flex-md-column">
        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Top 5 Forms By Response</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="
                top5FormResponseData && top5FormResponseData.length > 0;
                else noDataTemplateResponse
              "
            >
              <kendo-chart class="h-300">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    type="bar"
                    [gap]="2"
                    [spacing]="0.25"
                    [data]="top5FormResponseData"
                    field="data"
                    categoryField="name"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-tooltip>
                  <ng-template
                    kendoChartSeriesTooltipTemplate
                    let-value="value"
                    let-category="category"
                  >
                    {{ category }}:{{ value }}
                  </ng-template>
                </kendo-chart-tooltip>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateResponse>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon response d-flex justify-content-center align-items-center">
                  <i class="far fa-reply"></i>
                </div>
                <p class="subscribers mb-0">0 Top 5 Forms By Response</p>
                <span class="description">There are no Top 5 Forms By Response available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>

        <kendo-card class="wp-100">
          <kendo-card-header class="k-hstack">
            <h1 class="title">Drop-off Count</h1>
          </kendo-card-header>
          <kendo-card-body>
            <ng-container
              *ngIf="
                dashboardDropoffFormViewData && dashboardDropoffFormViewData.length > 0;
                else noDataTemplateCount
              "
            >
              <kendo-chart [categoryAxis]="dashboardDropoffFormViewCategory" class="h-300">
                <kendo-chart-series>
                  <kendo-chart-series-item
                    *ngFor="let data of dashboardDropoffFormViewData"
                    type="column"
                    [gap]="1"
                    [data]="data.data"
                    [name]="data.name"
                  >
                  </kendo-chart-series-item>
                  <kendo-chart-tooltip>
                    <ng-template
                      kendoChartSeriesTooltipTemplate
                      let-value="value"
                      let-category="category"
                    >
                      {{ category }}:{{ value }}
                    </ng-template>
                  </kendo-chart-tooltip>
                </kendo-chart-series>
              </kendo-chart>
            </ng-container>
            <ng-template #noDataTemplateCount>
              <div
                class="no-chart d-flex justify-content-center align-items-center flex-column gap-15 h-300"
              >
                <div class="icon count d-flex justify-content-center align-items-center">
                  <i class="far fa-calculator"></i>
                </div>
                <p class="subscribers mb-0">0 Drop-off Count</p>
                <span class="description">There are no Drop-off Count available yet!</span>
              </div>
            </ng-template>
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</div>
