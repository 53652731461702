import { Pipe, PipeTransform } from '@angular/core';
import { PublishState } from '@proxy/forms';

@Pipe({
  name: 'publishState',
})
export class PublishStatePipe implements PipeTransform {
  transform(publishState: number): string {
    switch (publishState) {
      case PublishState.Draft:
        return 'Draft';
      case PublishState.Private:
        return 'Private';
      case PublishState.Public:
        return 'Public';
      case PublishState.Anonymous:
        return 'Anonymous';
      case PublishState.Closed:
        return 'Closed';
      default:
        return 'Unknown';
    }
  }
}
