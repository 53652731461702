import { mapEnumToOptions } from '@abp/ng.core';

export enum FilterTypes {
  'Last 30 Days' = 0,
  'This Month' = 1,
  'This Quarter' = 2,
  'This Year' = 3,
  'Last Month' = 4,
  'Last Quarter' = 5,
  'Last Year' = 6,
  // 'Custom' = 7,
  'All' = -1,
}
export const FilterTypesOptions = mapEnumToOptions(FilterTypes);
