import type {
  CreationAuditedEntityDto,
  FullAuditedEntityDto,
  PagedAndSortedResultRequestDto,
} from '@abp/ng.core';
import type { PublishState } from '../publish-state.enum';

export interface UserWithoutDetails {
  id?: string;
  userName?: string;
  email?: string;
}

export interface AssigneeDetailDto {
  userId?: string;
  groupId: number;
  name?: string;
  email?: string;
  isUser: boolean;
}

export interface AssigneeDto extends FullAuditedEntityDto<number> {
  formId: number;
  userIds: string[];
  groupIds: number[];
  emailAddresses: string[];
}

export interface AssigneeGetListInput extends PagedAndSortedResultRequestDto {
  formId?: number;
}

export interface BarChartDto {
  data: string[];
  categories: string[];
  textLabel?: string;
}

export interface CreateUpdateAssigneeDto {
  formId: number;
  userIds: string[];
  groupIds: number[];
  emailAddresses: string[];
}

export interface CreateUpdateFormDto {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState: PublishState;
}

export interface CreateUpdateFormRevisionDto {
  formId: number;
  schemaJson?: string;
}

export interface CreateUpdateFormViewDto {
  formId: number;
}

export interface CreateUpdateGroupDto {
  name?: string;
}

export interface CreateUpdateGroupMemberDto {
  groupId: number;
  memberId?: string;
}

export interface CreateUpdateResponseDto {
  formId: number;
  responseData?: string;
  emailAddress?: string;
  isDraft?: boolean;
}

export interface FormDto extends FullAuditedEntityDto<number> {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState: PublishState;
  isFormOwner: boolean;
  isSurvey: boolean;
  responseCount: number;
  responseJson?: string;
  emailAddress?: string;
}

export interface FormGetListInput extends PagedAndSortedResultRequestDto {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState?: PublishState;
}

export interface FormRevisionDto extends CreationAuditedEntityDto<number> {
  formId: number;
  schemaJson?: string;
  emailAddress?: string;
  userName?: string;
}

export interface FormRevisionGetListInput extends PagedAndSortedResultRequestDto {
  formId: number;
}

export interface FormViewDto extends FullAuditedEntityDto<number> {
  formId: number;
}

export interface FormViewGetListInput extends PagedAndSortedResultRequestDto {
  formId: number;
}

export interface GroupDto extends FullAuditedEntityDto<number> {
  name?: string;
  members: string[];
}

export interface GroupGetListInput extends PagedAndSortedResultRequestDto {
  name?: string;
}

export interface GroupMemberGetListInput extends PagedAndSortedResultRequestDto {
  groupId: number;
}

export interface GroupMemberListDto {
  id?: string;
  userName?: string;
  email?: string;
}

export interface ResponseDto extends FullAuditedEntityDto<number> {
  formId: number;
  responseData?: string;
  emailAddress?: string;
  userName?: string;
}

export interface ResponseGetListInput extends PagedAndSortedResultRequestDto {
  formId?: number;
}

export interface SendEmailDto {
  emailAddresses: string[];
  subject?: string;
  message?: string;
  link?: string;
}

export interface ShareFormDto {
  formId: number;
  subject?: string;
  message?: string;
  link?: string;
  assignees: AssigneeDetailDto[];
}
