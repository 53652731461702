import { ConfigStateService } from '@abp/ng.core';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService, PublishState, ResponseService } from '@proxy/forms';
import {
  CreateUpdateFormDto,
  CreateUpdateFormViewDto,
  CreateUpdateResponseDto,
  ResponseGetListInput,
} from '@proxy/forms/dtos';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormioComponent } from '@formio/angular';
import { CommonService } from 'src/core/services/common.service';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { NbDialogService } from '@nebular/theme';
import { EmailComponent } from '../email/email.component';
import { AuthService } from '@abp/ng.core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { FormViewService } from '@proxy/forms/form-view.service';
@Component({
  selector: 'app-form-render',
  templateUrl: './form-render.component.html',
  styleUrls: ['./form-render.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormRenderComponent implements OnInit {
  @ViewChild('FormioComponent', { static: false }) formio: FormioComponent;
  formData: CreateUpdateFormDto;
  subscriptionRouting: Subscription;
  formId = 0;
  displayForm: any;
  options = {};
  userEmail: any;
  isModalOpen = true;
  isDraft: boolean;
  displayKey: any;
  publishState: any;
  isReadOnly = false;
  submission: any = {};
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  @ViewChild('json') jsonElement?: any;
  isCaptcha = false;
  siteKey = environment.recaptchaKey;
  isRenderForm = false;
  constructor(
    private router: Router,
    private formService: FormService,
    private _Activatedroute: ActivatedRoute,
    private responseService: ResponseService,
    private config: ConfigStateService,
    public activeModal: NgbActiveModal,
    private confirmation: ConfirmationService,
    private commonService: CommonService,
    private dialogService: DialogService,
    public authService: AuthService,
    private toasterService: ToasterService,
    private formViewService: FormViewService,
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.isReadOnly = false;
        let id = +atob(params.get('id')!);
        this.createFormView(id);
        if (params.get('isResponse') !== null && params.get('isResponse') !== undefined) {
          this.formId = +atob(params.get('formId')!);
          this.isReadOnly = true;
          this.isModalOpen = false;
          this.formService.get(this.formId).subscribe(res => {
            this.formData = res;
            this.displayForm = JSON.parse(res.schemaJson);
            this.responseService.get(+atob(params.get('id')!)).subscribe(res => {
              this.submission.data = JSON.parse(res.responseData);
              this.isRenderForm = true;
            });
          });
          this.createFormView(this.formId);
        } else {
          this.isRenderForm = true;
          this.formId = +atob(params.get('id')!);
          this.displayKey = atob(params.get('displayKey')!);
          this.publishState = +atob(params.get('publishState')!);
          if (this.publishState === PublishState.Private) {
            localStorage.setItem('isPrivateFormURL', this.router.url);
          }
          this.viewForm();
        }
      }
    });
  }

  getSchemaJsonByDisplaykey(displayKey: any, userEmail: string = ''): void {
    this.formService.getSchemaJsonByDisplaykey(displayKey, userEmail).subscribe(res => {
      if (res.publishState === PublishState.Private) {
        this.isModalOpen = false;
        localStorage.setItem('isPrivateRender', JSON.stringify(true));
      }
      if (res.publishState === PublishState.Closed || res.publishState === PublishState.Draft) {
        this.isModalOpen = true;
        this.confirmation.show('Survey Is Closed', 'Reponse are not allowed').subscribe(status => {
          this.router.navigate(['']);
        });
      } else {
        this.isModalOpen = false;
        this.formData = res;
        this.displayForm = JSON.parse(res.schemaJson);
        this.submission.data = JSON.parse(res.responseJson);
        this.options = {
          readOnly: false,
          hooks: {
            beforeRender: (instance: any, cb: any) => {
              if (instance && instance.submission && instance.submission.data.dataGrid) {
                const dataGrid = instance.submission.data.dataGrid;
                dataGrid.forEach((item: any, index: any) => {
                  item.serialNumber = index + 1;
                });
              }
              cb();
            },
          },
        };
      }
    });
  }
  closeModal() {
    this.activeModal.dismiss();
  }

  setEmail(email): void {
    this.isModalOpen = false;
    this.userEmail = email;
    this.getSchemaJsonByDisplaykey(this.displayKey, this.userEmail);
  }

  viewForm(): void {
    if (
      this.publishState === PublishState.Private ||
      this.publishState === PublishState.Anonymous
    ) {
      if (this.publishState === PublishState.Private) {
        const config = this.config.getAll();
        if (config.currentUser.isAuthenticated) {
          this.userEmail = config.currentUser.email;
          this.getSchemaJsonByDisplaykey(this.displayKey, this.userEmail);
        } else {
          this.router.navigate(['account']);
        }
      } else {
        this.userEmail = '';
        this.getSchemaJsonByDisplaykey(this.displayKey);
      }
    } else if (this.publishState === PublishState.Public) {
      this.isModalOpen = true;
      this.openEmailPopUp();
    }
  }

  onSubmit(event: any): void {
    this.isDraft = false;
    const param: CreateUpdateResponseDto = {
      formId: this.formId,
      responseData: JSON.stringify(event.data),
      emailAddress: this.userEmail,
      isDraft: this.isDraft,
    };
    this.responseService.create(param).subscribe(res => {
      if (
        this.publishState === PublishState.Anonymous ||
        this.publishState === PublishState.Public
      ) {
        this.router.navigate(['form-success']);
      } else {
        var params = {
          id: btoa(JSON.stringify(this.formId)),
        };
        this.toasterService.success(
          NotificationTextMessage.reponseAddedMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.router.navigate(['admin-View', params]);
      }
    });
  }

  saveForm(): void {
    if (this.formio) {
      this.formio.formio.emit('submitButton');
    }
  }

  handleSuccess(data: any) {
    this.isCaptcha = true;
  }

  openEmailPopUp(): void {
    const dialogRef = this.dialogService.open({
      content: EmailComponent,
      width: 450,
    });
    dialogRef.result.subscribe(result => {
      this.setEmail(result);
    });
  }

  onFormChange(event) {
    if (event.isModified !== undefined && this.publishState === PublishState.Private) {
      this.isDraft = true;
      const param: CreateUpdateResponseDto = {
        formId: this.formId,
        responseData: JSON.stringify(event.data),
        emailAddress: this.userEmail,
        isDraft: this.isDraft,
      };
      this.responseService.create(param).subscribe(res => {});
    }
  }

  createFormView(id) {
    let formId: CreateUpdateFormViewDto = {
      formId: id,
    };
    this.formViewService.create(formId).subscribe(res => {});
  }
}
