import type { FilterTypes } from '../enums/filter-types.enum';

export interface BarChartDetailModel {
  categories: string[];
  series: BarChartSeriesModel[];
}

export interface BarChartSeriesModel {
  name?: string;
  data: number[];
}

export interface DashboardInput {
  dateFilter: FilterModel;
}

export interface FilterModel {
  filter: FilterTypes;
  startDate?: string;
  endDate?: string;
}

export interface PieChartDetailModel {
  categories: string[];
  series: PieChartSeriesModel[];
}

export interface PieChartSeriesModel {
  name?: string;
  data: number;
}
