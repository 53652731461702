import type { CreateUpdateFormViewDto, FormViewDto, FormViewGetListInput } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormViewService {
  apiName = 'Default';

  create = (input: CreateUpdateFormViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormViewDto>(
      {
        method: 'POST',
        url: '/api/app/form-view',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/form-view/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormViewDto>(
      {
        method: 'GET',
        url: `/api/app/form-view/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getByDate = (startDate: string, endDate: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<FormViewDto>>(
      {
        method: 'GET',
        url: '/api/app/form-view/by-date',
        params: { startDate, endDate },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: FormViewGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<FormViewDto>>(
      {
        method: 'GET',
        url: '/api/app/form-view',
        params: {
          formId: input.formId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateFormViewDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FormViewDto>(
      {
        method: 'PUT',
        url: `/api/app/form-view/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
