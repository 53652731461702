import { AuthService, PagedResultDto } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@proxy/forms';
import { CreateUpdateGroupMemberDto, GroupMemberListDto } from '@proxy/forms/dtos';
import { Observable, Subscription, map } from 'rxjs';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { CommonService } from 'src/core/services/common.service';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { SVGIcon, cancelIcon } from '@progress/kendo-svg-icons';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-group-member',
  templateUrl: './add-group-member.component.html',
  styleUrls: ['./add-group-member.component.scss'],
})
export class AddGroupMemberPopupComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  gridView;
  gridData;
  public cancelIcon: SVGIcon = cancelIcon;
  formDataDisplay = { items: [], totalCount: 0 } as PagedResultDto<GroupMemberListDto>;
  subscriptionRouting: Subscription;
  groupId = 0;
  userList = [];
  sorting = null;
  selectedUser: any = [];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private groupService: GroupService,
    private _Activatedroute: ActivatedRoute,
    private confirmation: ConfirmationService,
    private commonService: CommonService,
    public authService: AuthService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.groupId = +atob(params.get('id')!);
        if (this.groupId > 0) {
          this.getUserList('');
          this.getMemberList();
        }
      }
    });
  }

  getUserList(search: string): void {
    this.groupService.getNonMemberList(this.groupId, search).subscribe(res => {
      this.userList = res.map(user => ({ text: user.userName, value: user.id }));
    });
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getMemberList();
  }

  onValueChange($event) {
    if ($event) {
      let userData = {
        text: $event.text,
        value: $event.value,
      };

      this.userList.push(userData); // si se quitan no muestra
      this.selectedUser = userData;
    } else {
      this.selectedUser = [];
    }
  }

  getMemberList(): void {
    const param: any = {
      groupId: this.groupId,
      maxResultCount: 10,
      skipCount: 0,
      sorting: this.sorting,
    };
    this.groupService.getMemberList(param).subscribe(response => {
      this.formDataDisplay = response;
      this.gridData = response.items;
      this.gridView = this.gridData;
    });
  }

  saveMember(): void {
    this.spinnerService.show();
    const param: CreateUpdateGroupMemberDto = {
      groupId: this.groupId,
      memberId: this.selectedUser.value,
    };
    this.groupService.addMember(param).subscribe(res => {
      this.toasterService.success(
        NotificationTextMessage.memberAddedMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.selectedUser = [];
      this.getMemberList();
      this.spinnerService.hide();
    });
  }

  delete(data: any): void {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this form?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        const param: CreateUpdateGroupMemberDto = {
          groupId: this.groupId,
          memberId: data.id,
        };
        this.groupService.removeMember(param).subscribe(res => {
          this.toasterService.success(
            NotificationTextMessage.memberRemovedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getMemberList();
        });
      }
    });
  }

  public onFilter(value: Event): void {
    const inputValue = value;

    this.gridView = process(this.gridData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'user_name',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'email',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'actions',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  valueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((text: string) => {
        return {
          value: 0,
          text: text,
        };
      }),
    );

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData(): void {
    this.gridView = {
      data: orderBy(this.gridData, this.sort),
      total: this.gridData.length,
    };
  }
}
