import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssigneeService, FormService } from '@proxy/forms';
import { ShareFormDto } from '@proxy/forms/dtos';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { CommonService } from 'src/core/services/common.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-send-form',
  templateUrl: './send-form.component.html',
  styleUrls: ['./send-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .item-border {
        border-bottom: 1px solid #e7e7e7;
        padding: 5px;
      }

      .k-listview {
        border-color: #e7e7e7;
      }
    `,
  ],
})
export class SendFormComponent implements OnInit {
  public xIcon: SVGIcon = xIcon;
  config: any = {
    height: 250,
    plugins: ['link', 'paste', 'table'],
    toolbar:
      'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    image_advtab: true,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    templates: [
      { title: 'Test template 1', content: 'Test 1' },
      { title: 'Test template 2', content: 'Test 2' },
    ],
    content_css: ['//fonts.googleapis.com/css?family=Lato:300,300i,400,400i'],
    setup: editor => {
      console.log(editor.ui);
    },
  };
  constructor(
    public activeModal: NgbActiveModal,
    private formService: FormService,
    private assigneeService: AssigneeService,
    protected ref: NbDialogRef<SendFormComponent>,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    private commonService: CommonService,
  ) {}

  emailForm: FormGroup;
  to: any;
  @Input() formURL: string;
  @Input() formId: number;

  emails: any = [];
  existingUserList: any[] = [];

  emailsFormData: any = [];
  assigneeDetailDto: any = [];

  ngOnInit(): void {
    this.setForm();
    this.getExistingUserList();
  }

  getExistingUserList(): void {
    if (this.formId !== undefined && this.formId !== null) {
      this.assigneeService.getByFormId(this.formId).subscribe(res => {
        this.existingUserList = res;
        console.log(' this.existingUserList', this.existingUserList);
      });
    }
  }

  setForm(): void {
    this.emailForm = new FormGroup({
      to: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl(''),
    });
  }

  onSubmit(emailData: any): void {
    if (this.emailForm.invalid) {
      this.emailForm.controls.to.markAsTouched();
      this.emailForm.controls.subject.markAsTouched();
      return;
    }

    this.emailsFormData.forEach(element => {
      emailData.to.forEach(emailEl => {
        if (element.email === emailEl || element.name === emailEl) {
          this.assigneeDetailDto.push(element);
        }
      });
    });

    const removeDuplicatesArrayById = this.removeDuplicates(this.assigneeDetailDto, 'userId');

    const param: ShareFormDto = {
      formId: this.formId,
      subject: emailData.subject,
      message: emailData.message,
      link: this.formURL,
      assignees: removeDuplicatesArrayById,
    };
    this.formService.share(param).subscribe(res => {
      if (res) {
        this.dialogRef.close();
        this.toasterService.success(
          NotificationTextMessage.emailSendMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      }
    });

    this.cancel();
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  onToChange(value: any): void {
    if (value !== null && value !== undefined && value.target.value !== '') {
      this.assigneeService.getContains(value.target.value).subscribe(res => {
        this.emails = [];
        if (res) {
          res.forEach(element => {
            this.emailsFormData.push(element);
            const isEmailExist = this.existingUserList.filter(
              x => x.userId === element.userId && x.groupId === element.groupId,
            );
            if (isEmailExist.length === 0) {
              if (element.isUser) {
                this.emails.push(element.email);
              } else {
                this.emails.push(element.name);
              }
            }
          });
        }
      });
    }
  }

  cancel() {
    this.ref.close();
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  public value: any = ['Baseball'];
}
