import type { AssigneeDto, AssigneeGetListInput, CreateUpdateAssigneeDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssigneeService {
  apiName = 'Default';

  create = (input: CreateUpdateAssigneeDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssigneeDto>(
      {
        method: 'POST',
        url: '/api/app/assignee',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/app/assignee/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssigneeDto>(
      {
        method: 'GET',
        url: `/api/app/assignee/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: AssigneeGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<AssigneeDto>>(
      {
        method: 'GET',
        url: '/api/app/assignee',
        params: {
          formId: input.formId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateAssigneeDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AssigneeDto>(
      {
        method: 'PUT',
        url: `/api/app/assignee/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
