import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import type { PubslishState } from '../pubslish-state.enum';

export interface AssigneeDto extends FullAuditedEntityDto<number> {
  formId: number;
  userIds: string[];
  groupIds: number[];
  emailAddresses: string[];
}

export interface AssigneeGetListInput extends PagedAndSortedResultRequestDto {
  formId?: number;
}

export interface CreateUpdateAssigneeDto {
  formId: number;
  userIds: string[];
  groupIds: number[];
  emailAddresses: string[];
}

export interface CreateUpdateFormDto {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState: PubslishState;
}

export interface CreateUpdateGroupDto {
  name?: string;
  members: string[];
}

export interface CreateUpdateGroupMemberDto {
  groupId: number;
  memberId?: string;
}

export interface CreateUpdateResponseDto {
  formId: number;
  responseData?: string;
  emailAddress?: string;
}

export interface FormDto extends FullAuditedEntityDto<number> {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState: PubslishState;
  isFormOwner: boolean;
}

export interface FormGetListInput extends PagedAndSortedResultRequestDto {
  title?: string;
  description?: string;
  content?: string;
  displayKey?: string;
  schemaJson?: string;
  publishState?: PubslishState;
}

export interface GroupDto extends FullAuditedEntityDto<number> {
  name?: string;
  members: string[];
}

export interface GroupGetListInput extends PagedAndSortedResultRequestDto {
  name?: string;
}

export interface GroupMemberGetListInput extends PagedAndSortedResultRequestDto {
  groupId: number;
}

export interface GroupMemberListDto {
  id?: string;
  userName?: string;
  email?: string;
}

export interface ResponseDto extends FullAuditedEntityDto<number> {
  formId: number;
  responseData?: string;
  emailAddress?: string;
  userName?: string;
}

export interface ResponseGetListInput extends PagedAndSortedResultRequestDto {
  formId?: number;
}

export interface SendEmailDto {
  emailAddresses: string[];
  subject?: string;
  message?: string;
  link?: string;
}
