import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddGroupMemberPopupComponent,
  AdminSurveyChartComponent,
  AdminViewComponent,
  CreateGroupComponent,
  CreateNewCustomerComponent,
  CustomerListComponent,
  FormBuilderComponent,
  FormListComponent,
  FormPreviewComponent,
  FormRenderAdminComponent,
  FormRenderComponent,
  GroupListComponent,
  SuccessResponseComponent,
} from './modules';
import { CustomAddTenantComponent } from './shared';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@abp/ng.account').then(m => m.AccountModule.forLazy()),
  },

  {
    path: 'identity',
    loadChildren: () => import('@abp/ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'tenant-management',
    loadChildren: () =>
      import('@abp/ng.tenant-management').then(m => m.TenantManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'form-buider',
    component: FormBuilderComponent,
  },
  {
    path: 'form-List',
    component: FormListComponent,
  },
  {
    path: 'form-render-admin',
    component: FormRenderAdminComponent,
  },
  {
    path: 'form-preview',
    component: FormPreviewComponent,
  },
  { path: 'admin-View', component: AdminViewComponent },
  {
    path: 'form-render',
    component: FormRenderComponent,
  },
  {
    path: 'form-success',
    component: SuccessResponseComponent,
  },
  {
    path: 'customerList',
    component: CustomerListComponent,
  },
  {
    path: 'createCustomer',
    component: CreateNewCustomerComponent,
  },
  {
    path: 'response-survey',
    component: AdminSurveyChartComponent,
  },
  {
    path: 'group-List',
    component: GroupListComponent,
  },
  {
    path: 'create-group',
    component: CreateGroupComponent,
  },
  {
    path: 'add-group-user',
    component: AddGroupMemberPopupComponent,
  },
  {
    path: 'TrialSignup',
    component: CustomAddTenantComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
