import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent {
  form: FormGroup;
  constructor(
    public dialogRef: DialogRef,
    private fb: FormBuilder,
  ) {
    this.setform();
  }

  setform(): void {
    this.form = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{1,}$'),
      ]),
    });
  }

  submit() {
    this.dialogRef.close(this.form.controls.email.value);
  }
}
