import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { GroupService } from '@proxy/forms';
import { CreateUpdateGroupDto } from '@proxy/forms/dtos';
import { Subscription } from 'rxjs';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { CommonService } from 'src/core/services/common.service';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent implements OnInit {
  public xIcon: SVGIcon = xIcon;
  subscriptionRouting: Subscription;
  groupForm: FormGroup;

  groupId = 0;
  headerText: string;
  title: string;
  listItemData: any;
  items: any[] = [];
  listItems: any[] = [];
  public value: any;

  constructor(
    private groupSerivce: GroupService,
    private commonService: CommonService,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.setForm();
    this.getAssignUserList();
  }

  ngOnInit(): void {}

  setOnLoadData(): void {
    this.headerText = this.groupId === 0 ? 'Create Group' : 'Edit Group';
    this.title = this.groupId === 0 ? 'Create Group' : 'update Group';
    if (this.groupId > 0) {
      this.getDataById();
    }
  }

  getAssignUserList(): void {
    const param: any = {
      name: '',
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.groupSerivce.getList(param).subscribe(response => {
      this.items = response.items;
      this.listItems = this.items.map(project => ({ text: project.name, value: project.id }));
      this.listItemData = this.listItems.slice();
    });
  }

  getDataById(): void {
    this.groupSerivce.get(this.groupId).subscribe(res => {
      this.groupForm.controls.name.setValue(res.name);
    });
  }

  setForm(): void {
    this.groupForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      assignUser: new FormControl(),
    });
  }

  onSubmit(): void {
    this.spinnerService.show();
    this.spinnerService.hide();
    if (this.groupForm.invalid) {
      this.groupForm.controls.name.markAsTouched();
      return;
    }

    const param: CreateUpdateGroupDto = {
      name: this.groupForm.controls.name.value,
    };

    const onComplete = () => {
      this.dialogRef.close();
    };

    const successMessage =
      this.groupId > 0
        ? NotificationTextMessage.groupAddedMessage
        : NotificationTextMessage.groupUpdatedMessage;

    const apiCall =
      this.groupId > 0
        ? this.groupSerivce.update(this.groupId, param)
        : this.groupSerivce.create(param);

    apiCall.subscribe(() => {
      this.toasterService.success(
        successMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.dialogRef.close();
      onComplete();
      this.spinnerService.hide();
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  public onProjectSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { text: string; id: number }) =>
      item.text.toLowerCase().includes(value.toLowerCase());

    this.listItems = this.listItems.filter(contains(searchTerm));
  }
}
