import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Formio } from 'formiojs';

@Component({
  selector: 'app-create-new-customer',
  templateUrl: './create-new-customer.component.html',
  styleUrls: ['./create-new-customer.component.css'],
})
export class CreateNewCustomerComponent implements OnInit {
  customerForm: any;
  dataList: any;
  options = {};
  constructor(private router: Router) {}

  ngOnInit(): void {
    const getForm = localStorage.getItem('createUserForm');
    if (getForm !== null && getForm !== undefined) {
      this.customerForm = JSON.parse(getForm);
    }

    this.options = {
      readOnly: false, // Set to true if you want to disable editing the grid
      hooks: {
        beforeRender: (instance: any, cb: any) => {
          // Generate Sr. No. values for the data grid
          if (instance && instance.submission && instance.submission.data.dataGrid) {
            const dataGrid = instance.submission.data.dataGrid;
            dataGrid.forEach((item: any, index: any) => {
              item.serialNumber = index + 1;
            });
          }
          cb();
        },
      },
    };
  }

  onSubmit(event: any): void {
    this.dataList = [];
    if (
      localStorage.getItem('customerList') !== null &&
      localStorage.getItem('customerList') !== undefined
    ) {
      const list: any = localStorage.getItem('customerList');
      this.dataList = JSON.parse(list);
      localStorage.removeItem('customerList');
    }
    this.dataList.push(event.data);
    localStorage.setItem('customerList', JSON.stringify(this.dataList));
    this.router.navigateByUrl('customerList');
  }
}
