<kendo-dialog-titlebar>
  <div>
    {{ '::Add Email' | abpLocalization }}
  </div>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="form" class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="email" text="Email" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="email" #email></kendo-textbox>
    </kendo-formfield>
  </form>
</div>

<kendo-dialog-actions>
  <div [ngClass]="{ 'curser-not-allowd': form.invalid }" class="text-align-right">
    <button kendoButton (click)="submit()" [disabled]="form.invalid">Save</button>
  </div>
</kendo-dialog-actions>
