import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '@proxy/forms';
import { CreateUpdateFormDto } from '@proxy/forms/dtos';
import { Subscription } from 'rxjs';
import { NotificationTextMessage } from 'src/core/enums/notification';
import { CommonService } from 'src/core/services/common.service';
import { AuthService } from '@abp/ng.core';
import { pubslishStateOptions } from 'app/proxy/src/proxy/forms';
import { DragDropComponent } from 'src/app/shared/enum/drag-drop-component-name.enum';
import { DragDropLblName } from 'src/app/shared/enum/component-name.enum';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormBuilderComponent implements OnInit {
  formData: CreateUpdateFormDto;
  @ViewChild('formioElement') formioElement: ElementRef;
  @ViewChild('json') jsonElement?: any;

  form: any = {
    components: [],
  };

  formField: FormGroup;
  subscriptionRouting: Subscription;
  publishStateList: any[] = [];
  formId = 0;

  isEdit = false;
  isShowBannerPopup = false;
  buttonName = 'Create Form';
  options: any = {
    noDefaultSubmitButton: true,
    builder: {
      basic: false,
      premium: false,
      advanced: false,
      layout: false,
      custom1: {
        title: 'Basic',
        weight: 10,
        default: true,
        components: {
          textfield: true,
          textarea: true,
          number: true,
          password: true,
          checkbox: true,
          selectboxes: {
            title: 'Multiple Checkbox',
            key: 'selectboxes',
            icon: 'plus-square',
            schema: {
              label: 'Multiple Checkbox',
              type: 'selectboxes',
              key: 'selectboxes',
              input: true,
            },
          },
          select: true,
          radio: true,
          button: true,
        },
      },
      custom2: {
        title: 'Advanced',
        weight: 10,
        components: {
          email: true,
          url: true,
          phoneNumber: true,
          tags: true,
          address: true,
          datetime: true,
          day: true,
          time: true,
          currency: true,
          survey: {
            title: 'Metrix',
            key: 'survey',
            icon: 'list',
            schema: {
              label: 'Metrix',
              type: 'survey',
              key: 'survey',
              input: true,
            },
          },
          signature: true,
        },
      },
      custom3: {
        title: 'Layout',
        weight: 10,
        components: {
          htmlelement: true,
          content: true,
          columns: true,
          fieldset: true,
          panel: true,
          table: true,
          tabs: true,
          well: {
            title: 'Box',
            key: 'well',
            icon: 'square-o',
            schema: {
              label: 'Box',
              type: 'well',
              key: 'well',
              input: true,
            },
          },
        },
      },
    },
  };

  bannerContent = '';

  constructor(
    private router: Router,
    private formService: FormService,
    private _Activatedroute: ActivatedRoute,
    public authService: AuthService,
    private toasterService: ToasterService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.isEdit = true;
        this.buttonName = 'Update Form';
        this.formId = +atob(params.get('id')!);
        this.editForm();
      }
    });
    this.publishStateList = pubslishStateOptions;
    this.setForm();
  }

  onChange(event): void {
    this.changeInnerHTML();
  }

  changeInnerHTML() {
    const elementWithLeadClass = document.querySelector('.lead');
    if (elementWithLeadClass) {
      elementWithLeadClass.innerHTML = this.updateLabel(elementWithLeadClass.innerHTML);
    }
  }

  updateLabel(lblValue: string): any {
    let lbl: any = '';
    switch (lblValue) {
      case DragDropComponent.TextFieldComponent:
        lbl = DragDropLblName.TextField;
        break;
      case DragDropComponent.TextAreaComponent:
        lbl = DragDropLblName.TextArea;
        break;
      case DragDropComponent.NumberComponent:
        lbl = DragDropLblName.Number;
        break;
      case DragDropComponent.PasswordComponent:
        lbl = DragDropLblName.Password;
        break;
      case DragDropComponent.CheckboxComponent:
        lbl = DragDropLblName.Checkbox;
        break;
      case DragDropComponent.SelectBoxesComponent:
        lbl = DragDropLblName.MultipleCheckbox;
        break;
      case DragDropComponent.SelectComponent:
        lbl = DragDropLblName.Select;
        break;
      case DragDropComponent.RadioComponent:
        lbl = DragDropLblName.Radio;
        break;
      case DragDropComponent.ButtonComponent:
        lbl = DragDropLblName.Button;
        break;
      case DragDropComponent.EmailComponent:
        lbl = DragDropLblName.Email;
        break;
      case DragDropComponent.UrlComponent:
        lbl = DragDropLblName.Url;
        break;
      case DragDropComponent.PhoneNumberComponent:
        lbl = DragDropLblName.PhoneNumber;
        break;
      case DragDropComponent.TagsComponent:
        lbl = DragDropLblName.Tags;
        break;
      case DragDropComponent.AddressComponent:
        lbl = DragDropLblName.Address;
        break;
      case DragDropComponent.Date_TimeComponent:
        lbl = DragDropLblName.Date_Time;
        break;
      case DragDropComponent.DayComponent:
        lbl = DragDropLblName.Day;
        break;
      case DragDropComponent.TimeComponent:
        lbl = DragDropLblName.Time;
        break;
      case DragDropComponent.CurrencyComponent:
        lbl = DragDropLblName.Currency;
        break;
      case DragDropComponent.SurveyComponent:
        lbl = DragDropLblName.Metrix;
        break;
      case DragDropComponent.SignatureComponent:
        lbl = DragDropLblName.Signature;
        break;
      case DragDropComponent.HTMLElementComponent:
        lbl = DragDropLblName.HTMLElement;
        break;
      case DragDropComponent.ContentComponent:
        lbl = DragDropLblName.Content;
        break;
      case DragDropComponent.ColumnsComponent:
        lbl = DragDropLblName.Columns;
        break;
      case DragDropComponent.FieldSetComponent:
        lbl = DragDropLblName.FieldSet;
        break;
      case DragDropComponent.PanelComponent:
        lbl = DragDropLblName.Panel;
        break;
      case DragDropComponent.TableComponent:
        lbl = DragDropLblName.Table;
        break;
      case DragDropComponent.TabsComponent:
        lbl = DragDropLblName.Tabs;
        break;
      case DragDropComponent.WellComponent:
        lbl = DragDropLblName.Box;
        break;
      case DragDropComponent.HiddenComponent:
        lbl = DragDropLblName.Hidden;
        break;
      case DragDropComponent.ContainerComponent:
        lbl = DragDropLblName.Container;
        break;
      case DragDropComponent.DataMapComponent:
        lbl = DragDropLblName.DataMap;
        break;
      case DragDropComponent.DataGridComponent:
        lbl = DragDropLblName.DataGrid;
        break;
      case DragDropComponent.EditGridComponent:
        lbl = DragDropLblName.EditGrid;
        break;
      case DragDropComponent.TreeComponent:
        lbl = DragDropLblName.Tree;
        break;
      default:
        lbl = lblValue;
        break;
    }
    return lbl;
  }

  editForm(): void {
    this.formService.get(this.formId).subscribe(res => {
      this.formField.patchValue({
        title: res.title,
        description: res.description,
        displayKey: res.displayKey,
        publishState: res.publishState,
      });
      this.form = JSON.parse(res.schemaJson);
      this.bannerContent = res.content;
    });
  }

  setForm(): void {
    this.formField = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      displayKey: new FormControl(''),
      publishState: new FormControl(0, [Validators.required]),
    });
  }

  saveForm(): void {
    this.spinner.show();
    if (this.formField.invalid) {
      this.formField.markAllAsTouched();
      return;
    } else if (this.form.components.length === 0) {
      return;
    }
    const successMessage = this.isEdit
      ? NotificationTextMessage.formUpdatedMessage
      : NotificationTextMessage.formAddedMessage;
    this.formData = {
      title: this.formField.controls.title.value,
      description: this.formField.controls.description.value,
      content: this.bannerContent,
      displayKey: this.formField.controls.displayKey.value,
      publishState: this.formField.controls.publishState.value,
      schemaJson: JSON.stringify(this.form),
    };

    const apiCall = this.isEdit
      ? this.formService.update(this.formId, this.formData)
      : this.formService.create(this.formData);

    apiCall.subscribe(() => {
      this.toasterService.success(
        successMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.router.navigateByUrl('form-List');
      this.spinner.hide();
    });
  }

  closeBanner(): void {
    this.isShowBannerPopup = false;
  }

  saveBanner(): void {
    this.isShowBannerPopup = true;
  }
}
