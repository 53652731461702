<div class="q-content">
  <kendo-tabstrip (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab title="Preview" [selected]="selectedTabIndex === 0">
      <ng-template kendoTabContent>
        <app-form-render-admin [formId]="formId"></app-form-render-admin>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Response" [selected]="selectedTabIndex === 1">
      <ng-template kendoTabContent>
        <app-admin-view [adminViewId]="formId"></app-admin-view>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Report" [selected]="selectedTabIndex === 2">
      <ng-template kendoTabContent>
        <app-admin-survey-chart [formId]="formId"></app-admin-survey-chart>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
