<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-content-header">
    <div class="d-flex justify-content-between mb-20">
      <kendo-textbox
        placeholder="Search for form name"
        class="search"
        (keyup.enter)="onSearch($event)"
        [(ngModel)]="searchText"
        (ngModelChange)="onSearchTextChange($event)"
        ><ng-template kendoTextBoxPrefixTemplate>
          <i class="fas fa-search k-icon font-size-14"></i> </ng-template
      ></kendo-textbox>
      <div class="d-flex gap-15">
        <button kendoButton type="button" [svgIcon]="plusIcon" (click)="createForm()">
          <span><i class="far fa-plus mr-10"></i>{{ '::New Form' | abpLocalization }}</span>
        </button>
        <kendo-dropdownlist
          [data]="statusList"
          textField="text"
          valueField="value"
          (valueChange)="getList()"
          [(ngModel)]="selectedStatus"
          [value]="selectedStatus"
          [valuePrimitive]="false"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ dataItem.text }}
          </ng-template>
        </kendo-dropdownlist>
        <div class="d-flex align-items-center q-toggle-button">
          <button
            [class.k-selected]="isListView"
            kendoButton
            fillMode="flat"
            (click)="showListView(true)"
          >
            <i class="fas fa-list-ul font-size-16"></i>
          </button>
          <button
            [class.k-selected]="!isListView"
            kendoButton
            fillMode="flat"
            (click)="showListView(false)"
          >
            <i class="fas fa-th-large font-size-16"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="q-content-bg grid-wrapper" *ngIf="isListView">
    <kendo-grid
      #grid
      [data]="gridView"
      [pageSize]="pageSize"
      [skip]="skip"
      [pageable]="
        formList === undefinded
          ? false
          : {
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext,
              position: pagerposition
            }
      "
      [height]="gridHeight"
      (pageChange)="pageChange($event)"
      [sortable]="{
        allowUnsort: true,
        mode: multiple ? 'multiple' : 'single'
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
    >
      <kendo-grid-column field="title" title="Title" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.title }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="description" title="Description" [width]="150" [resizable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{ dataItem.description }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="publishState"
        title="State"
        [width]="100"
        [resizable]="false"
        filter="boolean"
        class="k-status"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="k-status-label k-bg-public"
            *ngIf="dataItem.publishState === pubslishStateEnum.Public"
          >
            <span class="k-status-name">
              {{ dataItem.publishState | publishState }}
            </span>
          </div>
          <div
            class="k-status-label k-bg-anonymous"
            *ngIf="dataItem.publishState === pubslishStateEnum.Anonymous"
          >
            <span class="k-status-name">
              {{ dataItem.publishState | publishState }}
            </span>
          </div>
          <div
            class="k-status-label k-bg-private"
            *ngIf="dataItem.publishState === pubslishStateEnum.Private"
          >
            <span class="k-status-name">
              {{ dataItem.publishState | publishState }}
            </span>
          </div>
          <div
            class="k-status-label k-bg-draft"
            *ngIf="dataItem.publishState === pubslishStateEnum.Draft"
          >
            <span class="k-status-name">
              {{ dataItem.publishState | publishState }}
            </span>
          </div>
          <div
            class="k-status-label k-bg-close"
            *ngIf="dataItem.publishState === pubslishStateEnum.Closed"
          >
            <span class="k-status-name">
              {{ dataItem.publishState | publishState }}
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="responseCount"
        title="Total Response"
        [resizable]="false"
        filter="boolean"
        [width]="150"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between wp-100">
            <div>{{ dataItem.responseCount }}</div>

            <ul class="d-flex justify-content-center align-items-center">
              <li class="action-menu-dropdown grid-action-list" ngbDropdown container="body">
                <a aria-haspopup="true" ngbDropdownToggle>
                  <i class="fas fa-ellipsis-h"></i>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="canEdit"
                      title="edit"
                      (click)="edit(dataItem.id)"
                      ><i class="far fa-pen"></i> <span class="dropdown-item-name">Edit</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="canDelete"
                      title="delete"
                      (click)="delete(dataItem.id)"
                      ><i class="far fa-trash-alt"></i
                      ><span class="dropdown-item-name">Delete</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="
                        dataItem.publishState !== pubslishStateEnum.Draft &&
                        dataItem.publishState !== pubslishStateEnum.Closed &&
                        canCreate &&
                        dataItem.isFormOwner
                      "
                      #container
                      title="share"
                      (click)="shareView(dataItem)"
                      ><i class="far fa-share-nodes"></i
                      ><span class="dropdown-item-name">Share</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="canCreate"
                      title="view"
                      (click)="view(dataItem.id)"
                      ><i class="far fa-eye"></i><span class="dropdown-item-name">View</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="
                        (dataItem.isFormOwner || isAdmin) &&
                        dataItem.publishState !== pubslishStateEnum.Draft
                      "
                      title="reponse"
                      (click)="adminView(dataItem.id)"
                      ><i class="far fa-repeat"></i
                      ><span class="dropdown-item-name">Response</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="!dataItem.isFormOwner && !isAdmin"
                      title="response"
                      (click)="responseView(dataItem)"
                      ><i class="far fa-repeat"></i>
                      <span class="dropdown-item-name">Response</span></a
                    >
                  </li>

                  <li class="drodpown-list">
                    <a
                      class="dropdown-item"
                      *ngIf="
                        dataItem.isSurvey &&
                        dataItem.isFormOwner &&
                        dataItem.publishState !== pubslishStateEnum.Draft
                      "
                      title="survey"
                      (click)="viewSurvey(dataItem.id)"
                      ><i class="fa-sharp far fa-file-chart-column"></i
                      ><span class="dropdown-item-name">Report</span></a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div *ngIf="!isListView">
    <div class="d-flex-row">
      <div
        *ngFor="let project of formList"
        class="d-flex-col-sm-6 d-flex-col-lg-3 d-flex-col mb-20"
      >
        <kendo-card class="wp-100 h-100">
          <kendo-card-header class="k-hstack">
            <div class="d-flex align-items-center justify-content-between wp-100">
              <h1 kendoCardTitle title="{{ project.title }}" class="clamp">
                {{ project.title }}
              </h1>
            </div>
            <div>
              <ul>
                <li
                  class="action-menu-dropdown d-flex justify-content-center align-items-center"
                  ngbDropdown
                  container="body"
                >
                  <a aria-haspopup="true" ngbDropdownToggle>
                    <i class="fas fa-ellipsis-h"></i>
                  </a>
                  <ul class="dropdown-menu" ngbDropdownMenu>
                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="canEdit"
                        title="edit"
                        (click)="edit(project.id)"
                        ><i class="far fa-pen"></i> <span class="dropdown-item-name">Edit</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="canDelete"
                        title="delete"
                        (click)="delete(project.id)"
                        ><i class="far fa-trash-alt"></i
                        ><span class="dropdown-item-name">Delete</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="
                          project.publishState !== pubslishStateEnum.Draft &&
                          project.publishState !== pubslishStateEnum.Closed &&
                          canCreate &&
                          project.isFormOwner
                        "
                        #container
                        title="share"
                        (click)="shareView(project)"
                        ><i class="far fa-share-nodes"></i
                        ><span class="dropdown-item-name">Share</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="canCreate"
                        title="view"
                        (click)="view(project.id)"
                        ><i class="far fa-eye"></i><span class="dropdown-item-name">View</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="
                          (project.isFormOwner || isAdmin) &&
                          project.publishState !== pubslishStateEnum.Draft
                        "
                        title="reponse"
                        (click)="adminView(project.id)"
                        ><i class="far fa-repeat"></i
                        ><span class="dropdown-item-name">Response</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="!project.isFormOwner && !isAdmin"
                        title="response"
                        (click)="responseView(project)"
                        ><i class="far fa-repeat"></i
                        ><span class="dropdown-item-name">Response</span></a
                      >
                    </li>

                    <li class="drodpown-list">
                      <a
                        class="dropdown-item"
                        *ngIf="
                          project.isSurvey &&
                          project.isFormOwner &&
                          project.publishState !== pubslishStateEnum.Draft
                        "
                        title="survey"
                        (click)="viewSurvey(project.id)"
                        ><i class="fa-sharp far fa-file-chart-column"></i
                        ><span class="dropdown-item-name">Report</span></a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </kendo-card-header>
          <kendo-card-body>
            <p kendoCardSubtitle class="fw-normal mb-5">Description:</p>
            <div class="height-clamp" title="{{ project.description }}">
              {{ project.description }}
            </div>
          </kendo-card-body>
          <kendo-card-footer class="d-flex justify-content-between">
            <p><span class="fw-500">State :</span> {{ project.publishState | publishState }}</p>
            <p><span class="fw-500">Total Response :</span> {{ project.responseCount }}</p>
          </kendo-card-footer>
        </kendo-card>
      </div>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>
