import { AuthService, PagedResultDto } from '@abp/ng.core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SVGIcon, eyeIcon, moreHorizontalIcon } from '@progress/kendo-svg-icons';
import { ResponseService } from '@proxy/forms';
import { ResponseDto, ResponseGetListInput } from '@proxy/forms/dtos';
import { Subscription } from 'rxjs';
import { NullStringPipe } from 'src/app/pipes/null-string.pipe';
@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css'],
  providers: [DecimalPipe, NullStringPipe],
})
export class AdminViewComponent implements OnInit {
  @Input() adminViewId: any;
  subscriptionRouting: Subscription;
  adminViewData: any;
  isViewData = false;
  adminViewKeys: any;
  responceDataDisplay = { items: [], totalCount: 0 } as PagedResultDto<ResponseDto>;
  sorting = null;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  gridView;
  gridData;
  public eyeIcons: SVGIcon = eyeIcon;

  constructor(
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    private responseService: ResponseService,
    public datePipe: DatePipe,
    public authService: AuthService,
    private nullString: NullStringPipe,
  ) {}

  ngOnInit() {
    this.getList();
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getList();
  }

  getList(): void {
    var params = {
      id: btoa(this.adminViewId),
    };
    let payload: ResponseGetListInput = {
      formId: this.adminViewId,
      maxResultCount: 10,
      skipCount: 0,
      sorting: this.sorting,
    };
    this.responseService.getList(payload).subscribe(response => {
      response.items.forEach(element => {
        element.creationTime = this.datePipe.transform(element.creationTime, 'MM-dd-yyyy');
        element.userName = this.nullString.transform(element.userName);
        element.emailAddress = this.nullString.transform(element.emailAddress);
      });
      this.responceDataDisplay = response;
      this.gridData = this.responceDataDisplay.items;
      this.gridView = this.gridData;
    });
  }

  delete(id: any): void {
    this.responseService.delete(id).subscribe(res => {
      this.getList();
    });
  }

  view(data: any): void {
    var params = {
      formId: btoa(data.formId),
      id: btoa(data.id),
      isResponse: btoa(JSON.stringify(true)),
    };
    this.router.navigate(['form-render', params]);
  }
}
