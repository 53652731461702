import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { UserWithoutDetails } from '../forms/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiName = 'Default';

  getContains = (search: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserWithoutDetails[]>(
      {
        method: 'GET',
        url: '/api/app/user/contains',
        params: { search },
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
