<button type="button" class="btn btn-primary" (click)="addCustomer()">Add Customer</button>
<form>
  <div class="form-group form-inline">
    Search:
    <input
      class="form-control ml-2"
      type="text"
      name="searchTerm"
      [(ngModel)]="service.searchTerm"
    />
    <span class="ml-3" *ngIf="service.loading$ | async">Loading...</span>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col" sortable="firstName" (sort)="onSort($event)">Firt Name</th>
        <th scope="col" sortable="lastName" (sort)="onSort($event)">Last Name</th>
        <th scope="col" sortable="isAdmin" (sort)="onSort($event)">Admin</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let customer of customerList$ | async; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>
          <ngb-highlight [result]="customer.firstName" [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="customer.lastName" [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="customer.isAdmin" [term]="service.searchTerm"></ngb-highlight>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination
      [collectionSize]="total$ | async"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
    >
    </ngb-pagination>

    <select
      class="custom-select"
      style="width: auto"
      name="pageSize"
      [(ngModel)]="service.pageSize"
    >
      <option [ngValue]="2">2 items per page</option>
      <option [ngValue]="4">4 items per page</option>
      <option [ngValue]="6">6 items per page</option>
    </select>
  </div>
</form>
