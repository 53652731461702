export enum NotificationTextMessage {
  groupAddedMessage = 'Group Updated Successfully!',
  groupUpdatedMessage = 'Group Saved Successfully!',
  groupDeletedMessage = 'Group Deleted Successfully!',
  formAddedMessage = 'Form Created Successfully!',
  formUpdatedMessage = 'Form Updated Successfully!',
  formDeletedMessage = 'Form Deleted Successfully!',
  reponseAddedMessage = 'Reponse Saved Successfully!',
  shareLinkMessage = 'Share link Copied',
  emailSendMessage = 'Email Send Successfully!',
  memberAddedMessage = 'User added to the group successfully!',
  memberRemovedMessage = 'User deleted from the group successfully!',
}

export enum NotificationHeader {
  success = 'Success!',
  error = 'Error!',
}

export enum ConfirmationHeaderText {
  groupDeletion = 'Are you sure to delete this group ?',
  error = 'Error!',
}
