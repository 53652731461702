import { ListService, PermissionService, AuthService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { DOCUMENT, DecimalPipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormService, PublishState, ResponseService } from '@proxy/forms';
import { ResponseGetListInput } from '@proxy/forms/dtos';
import { ClipboardService } from 'ngx-clipboard';
import { SendFormComponent } from './send-form/send-form.component';
import { NotificationTextMessage } from 'src/core/enums/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ActionListEnum } from 'src/core/enums/action';
import { PreviewType } from 'src/core/enums/preview-type';
import { CommonService } from 'src/core/services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
  providers: [DecimalPipe],
})
export class FormListComponent implements OnInit {
  isMobile: boolean = false;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  isListView = true;
  isAdmin = false;

  pubslishStateEnum = PublishState;

  gridHeight: number;
  selectedStatus = { value: -1, text: 'All' };
  searchText = '';

  originalFormList: any[];
  formList: any[];

  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 20;
  public skip = 0;
  sorting = null;
  public multiple = false;
  public allowUnsort = true;
  //#endregion

  @Input() formURL;
  @Input() formId;

  statusList: Array<{ text: string; value: number }> = [
    { text: PublishState[PublishState.All], value: PublishState.All },
    { text: PublishState[PublishState.Public], value: PublishState.Public },
    { text: PublishState[PublishState.Private], value: PublishState.Private },
    { text: PublishState[PublishState.Draft], value: PublishState.Draft },
    { text: PublishState[PublishState.Anonymous], value: PublishState.Anonymous },
    { text: PublishState[PublishState.Closed], value: PublishState.Closed },
  ];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private router: Router,
    public readonly list: ListService,
    private formService: FormService,
    private clipboardService: ClipboardService,
    private permissionService: PermissionService,
    private responseService: ResponseService,
    public authService: AuthService,
    private dialogService: DialogService,
    private commonService: CommonService,
    @Inject(DOCUMENT) public document,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.canCreate = this.permissionService.getGrantedPolicy('QForms.Form.Create');
    this.canEdit = this.permissionService.getGrantedPolicy('QForms.Form.Update');
    this.canDelete = this.permissionService.getGrantedPolicy('QForms.Form.Delete');
    this.isAdmin = this.permissionService.getGrantedPolicy('AbpIdentity.Roles');
  }

  ngOnInit() {
    this.getList();

    this.checkScreenSize();
    this.calculateGridHeight();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
      this.calculateGridHeight();
    });
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 450;
    this.isListView = !this.isMobile;
  }

  getList(): void {
    this.spinnerService.show();
    this.selectedStatus.value;

    let data = {
      publishState: +this.selectedStatus.value,
      maxResultCount: this.pageSize,
    };

    this.formService.getList(data).subscribe(res => {
      this.formList = res.items;
      this.originalFormList = res.items;

      this.loadItems();
      this.spinnerService.hide();
    });
  }

  createForm(): void {
    this.router.navigateByUrl('form-buider');
  }

  showListView(isListView): void {
    this.isListView = isListView;
  }

  view(id: any): void {
    var params = {
      id: btoa(id),
      previewTypeId: btoa(PreviewType.Preview.toString()),
    };
    this.router.navigate(['form-preview', params]);
  }

  responseView(data: any): void {
    let payload: ResponseGetListInput = {
      formId: data.id,
      maxResultCount: 10,
      skipCount: 0,
      sorting: null,
    };
    this.responseService.getList(payload).subscribe(response => {
      if (response.totalCount > 0) {
        let responseId = response.items[0].id;
        var params = {
          formId: btoa(data.id),
          id: btoa(JSON.stringify(responseId)),
          isResponse: btoa(JSON.stringify(true)),
        };
        this.router.navigate(['form-render', params]);
      }
    });
  }
  viewSurvey(id): void {
    var params = {
      id: btoa(id),
      previewTypeId: btoa(PreviewType.Report.toString()),
    };
    this.router.navigate(['form-preview', params]);
  }

  shareView(data: any): void {
    if (data.publishState === PublishState.Private) {
      this.sendEmail(data);
    } else {
      var params = {
        id: btoa(data.id),
        displayKey: btoa(data.displayKey),
        publishState: btoa(data.publishState),
      };

      const url: any =
        this.document.location.origin +
        '/form-render' +
        ';id=' +
        params.id +
        ';displayKey=' +
        params.displayKey +
        ';publishState=' +
        params.publishState;

      this.clipboardService.copyFromContent(url);
      this.toasterService.success(
        NotificationTextMessage.shareLinkMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  edit(id: any): void {
    var params = {
      id: btoa(id),
    };
    this.router.navigate(['form-buider', params]);
  }

  delete(id: any): void {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this form?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        this.formService.delete(id).subscribe(() => {
          this.toasterService.success(
            NotificationTextMessage.formDeletedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.getList();
        });
      }
    });
  }

  adminView(id: any): void {
    var params = {
      id: btoa(id),
      previewTypeId: btoa(PreviewType.Response.toString()),
    };
    this.router.navigate(['form-preview', params]);
  }

  sendEmail(data: any): void {
    var params = {
      id: btoa(data.id),
      displayKey: btoa(data.displayKey),
      publishState: btoa(data.publishState),
    };

    const url: any =
      this.document.location.origin +
      '/form-render' +
      ';id=' +
      params.id +
      ';displayKey=' +
      params.displayKey +
      ';publishState=' +
      params.publishState;
    const dialogRef = this.dialogService.open({
      content: SendFormComponent,
      width: 450,
    });
    const sendFormInfo = dialogRef.content.instance as SendFormComponent;
    sendFormInfo.formId = data.id;
    sendFormInfo.formURL = url;
    dialogRef.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      }
    });
  }

  onSort(event) {
    this.sorting = event.column.prop + ' ' + event.column.newValue;
    this.getList();
  }

  // public onFilter(value: Event): void {
  //   const inputValue = value;

  //   this.gridView = process(this.gridData, {
  //     filter: {
  //       logic: 'or',
  //       filters: [
  //         {
  //           field: 'title',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'description',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'state',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'total_response',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //         {
  //           field: 'actions',
  //           operator: 'contains',
  //           value: inputValue,
  //         },
  //       ],
  //     },
  //   }).data;

  //   this.dataBinding.skip = 0;
  // }

  onActionSelect(selectedAction: any, data: any) {
    const selectedFunction = selectedAction.text;
    const trimmedSelectedFunction = selectedFunction.trim(); // Trim whitespace
    const trimmedActionDelete = ActionListEnum.delete.trim(); // Trim whitespace
    const trimmedActioneEdit = ActionListEnum.edit.trim(); // Trim whitespace
    const trimmedActioneShare = ActionListEnum.share.trim(); // Trim whitespace
    const trimmedActioneView = ActionListEnum.view.trim(); // Trim whitespace
    const trimmedActioneResponse = ActionListEnum.response.trim(); // Trim whitespace
    const trimmedActioneReport = ActionListEnum.report.trim(); // Trim whitespace
    const trimmedPublishDarftState = PublishState.Draft; // Trim whitespace
    const trimmedPublisClosedState = PublishState.Closed; // Trim whitespace

    if (trimmedSelectedFunction === trimmedActionDelete && this.canDelete) {
      this.delete(data.id);
    } else if (trimmedSelectedFunction === trimmedActioneEdit && this.canEdit) {
      this.edit(data.id);
    } else if (
      trimmedSelectedFunction === trimmedActioneShare &&
      data.publishState !== trimmedPublishDarftState &&
      data.publishState !== trimmedPublisClosedState &&
      this.canCreate &&
      data.isFormOwner
    ) {
      this.shareView(data);
    } else if (trimmedSelectedFunction === trimmedActioneView && this.canCreate) {
      this.view(data.id);
    } else if (
      trimmedSelectedFunction === trimmedActioneResponse &&
      (data.isFormOwner || this.isAdmin)
    ) {
      this.adminView(data.id);
    } else if (
      trimmedSelectedFunction === trimmedActioneResponse &&
      !(data.isFormOwner || this.isAdmin)
    ) {
      this.responseView(data);
    } else if (
      trimmedSelectedFunction === trimmedActioneReport &&
      data.isSurvey &&
      data.isFormOwner &&
      data.publishState !== trimmedPublishDarftState
    ) {
      this.viewSurvey(data.id);
    }
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 84;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  onSearch(event: any): void {
    const filteredListData = process(
      this.originalFormList.slice(this.skip, this.skip + this.pageSize),
      {
        filter: {
          logic: 'or',
          filters: [
            { field: 'title', operator: 'contains', value: this.searchText },
            { field: 'description', operator: 'contains', value: this.searchText },
          ],
        },
      },
    );

    const filteredGridData = process(this.originalFormList, {
      filter: {
        logic: 'or',
        filters: [
          { field: 'title', operator: 'contains', value: this.searchText },
          { field: 'description', operator: 'contains', value: this.searchText },
        ],
      },
    });

    this.gridView.data = filteredListData.data;
    this.formList = filteredGridData.data;
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.loadItems();
  }

  public loadItems(): void {
    this.gridView = {
      data: this.originalFormList.slice(this.skip, this.skip + this.pageSize),
      total: this.originalFormList.length,
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }

  loadData(): void {
    this.gridView = {
      data: orderBy(this.formList, this.sort),
      total: this.formList.length,
    };
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.getList();
    }
  }
}
