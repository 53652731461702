<kendo-dialog-titlebar (close)="visible = false" *ngIf="visible" class="m-0 pt-0 pb-10">
  <div>
    {{ 'AbpFeatureManagement::Features' | abpLocalization }}
  </div>
</kendo-dialog-titlebar>

<div>
  <ng-container *ngIf="groups.length">
    <div>
      <kendo-tabstrip>
        <kendo-tabstrip-tab
          *ngFor="let group of groups; let x = index; trackBy: track.by('name')"
          [selected]="selectedTabIndex === x"
        >
          <ng-template kendoTabTitle>{{ group.displayName }}</ng-template>
          <ng-template kendoTabContent>
            <!-- <h4>{{ selectedGroupDisplayName }}</h4> -->
            <div
              *ngFor="let feature of features[group.name]; let i = index; trackBy: track.by('id')"
              [ngStyle]="feature.style"
              [ngSwitch]="feature.valueType?.name"
              (keyup.enter)="save()"
            >
              <ng-container *ngSwitchCase="valueTypes.ToggleStringValueType" class="mb-20">
                <div>
                  <input
                    kendoCheckBox
                    [id]="feature.name"
                    [(ngModel)]="feature.value"
                    (ngModelChange)="onCheckboxClick($event, feature)"
                  />
                  <kendo-label
                    labelCssClass="k-form-label form-label"
                    [htmlFor]="feature.name"
                    text="{{ feature.displayName }}"
                  ></kendo-label>
                  <ng-container
                    class="mb-20"
                    *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                  ></ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="valueTypes.FreeTextStringValueType" class="mb-20">
                <div>
                  <kendo-label
                    labelCssClass="k-form-label form-label"
                    [htmlFor]="feature.name"
                    text="{{ feature.displayName }}"
                  ></kendo-label>
                  <input
                    kendoTextBox
                    [id]="feature.name"
                    [(ngModel)]="feature.value"
                    [abpFeatureManagementFreeText]="feature"
                  />
                  <ng-container
                    *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                  ></ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="valueTypes.SelectionStringValueType" class="mb-20">
                <ng-container *ngIf="feature.valueType.itemSource?.items?.length">
                  <kendo-formfield class="mb-20">
                    <kendo-label labelCssClass="k-form-label form-label" [htmlFor]="feature.name">{{
                      feature.displayName
                    }}</kendo-label>
                    <kendo-dropdownlist
                      [data]="feature.valueType.itemSource?.items"
                      [textField]="'displayText.name'"
                      [valueField]="'value'"
                      [(ngModel)]="feature.value"
                    ></kendo-dropdownlist>
                    <ng-container
                      *ngTemplateOutlet="descTmp; context: { $implicit: feature.description }"
                    ></ng-container>
                  </kendo-formfield>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault class="mb-20">{{ feature.displayName }}</ng-container>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>

    <ng-template #descTmp let-description>
      <small *ngIf="description" class="d-block form-text text-muted">{{ description }}</small>
    </ng-template>

    <div></div>
  </ng-container>

  <div *ngIf="!groups.length">
    {{ 'AbpFeatureManagement::NoFeatureFoundMessage' | abpLocalization }}
  </div>
</div>
<kendo-dialog-actions class="m-0 pt-2 pb-10">
  <button kendoButton (click)="resetToDefault()">
    {{ 'AbpFeatureManagement::ResetToDefault' | abpLocalization }}
  </button>
  <button kendoButton (click)="visible = false" fillMode="outline">
    {{ 'AbpFeatureManagement::Cancel' | abpLocalization }}
  </button>
  <button kendoButton (click)="save()">
    {{ 'AbpIdentity::Save' | abpLocalization }}
  </button>
</kendo-dialog-actions>
