<!-- <h3>{{ 'AbpSettingManagement::Menu:Emailing' | abpLocalization }}</h3> -->

<form
  *ngIf="form"
  [formGroup]="form"
  class="abp-md-form k-form k-form-md"
  (ngSubmit)="submit()"
  [validateOnSubmit]="true"
>
  <kendo-formfield class="mb-20">
    <kendo-label
      labelCssClass="k-form-label"
      text="{{ 'AbpSettingManagement::DefaultFromDisplayName' | abpLocalization }}"
      ><span class="required">*</span></kendo-label
    >
    <kendo-textbox type="text" formControlName="defaultFromDisplayName"></kendo-textbox>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <kendo-label
      labelCssClass="k-form-label"
      text="{{ 'AbpSettingManagement::DefaultFromAddress' | abpLocalization }}"
      ><span class="required">*</span></kendo-label
    >
    <kendo-textbox type="text" formControlName="defaultFromAddress"></kendo-textbox>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <kendo-label
      labelCssClass="k-form-label"
      text="{{ 'AbpSettingManagement::SmtpHost' | abpLocalization }}"
    ></kendo-label>
    <kendo-textbox type="text" formControlName="smtpHost"></kendo-textbox>
  </kendo-formfield>
  <kendo-formfield class="mb-20">
    <kendo-label
      labelCssClass="k-form-label"
      text="{{ 'AbpSettingManagement::SmtpPort' | abpLocalization }}"
      ><span class="ms-1">*</span></kendo-label
    >
    <kendo-textbox type="number" formControlName="smtpPort"></kendo-textbox>
  </kendo-formfield>

  <div class="mb-20 checkbox-container d-flex align-items-center">
    <input type="checkbox" formControlName="smtpEnableSsl" kendoCheckBox #smtpEnableSsl />
    <kendo-label
      class="ml-5"
      [for]="smtpEnableSsl"
      text="{{ 'AbpSettingManagement::SmtpEnableSsl' | abpLocalization }}"
      labelCssClass="k-form-label"
    ></kendo-label>
  </div>
  <div class="mb-20 checkbox-container d-flex align-items-center">
    <input
      type="checkbox"
      id="smtpUseDefaultCredentials"
      kendoCheckBox
      formControlName="smtpUseDefaultCredentials"
    />
    <kendo-label
      class="ml-5"
      for="smtpUseDefaultCredentials"
      text="{{ 'AbpSettingManagement::SmtpUseDefaultCredentials' | abpLocalization }}"
      labelCssClass="k-form-label"
    ></kendo-label>
  </div>

  <div
    [@collapse]="{
      value: form.get('smtpUseDefaultCredentials')?.value ? 'collapsed' : 'expanded',
      params: { time: '200ms', easing: 'linear' }
    }"
  >
    <kendo-formfield class="mb-20">
      <kendo-label
        labelCssClass="k-form-label"
        text="{{ 'AbpSettingManagement::SmtpDomain' | abpLocalization }}"
      ></kendo-label>
      <kendo-textbox type="text" formControlName="smtpDomain"></kendo-textbox>
    </kendo-formfield>

    <kendo-formfield class="mb-20">
      <kendo-label
        labelCssClass="k-form-label"
        txet="{{ 'AbpSettingManagement::SmtpUserName' | abpLocalization }}"
      ></kendo-label>
      <kendo-textbox type="text" formControlName="smtpUserName"></kendo-textbox>
    </kendo-formfield>

    <kendo-formfield class="mb-20">
      <kendo-label
        labelCssClass="k-form-label"
        text="{{ 'AbpSettingManagement::SmtpPassword' | abpLocalization }}"
      ></kendo-label>
      <kendo-textbox type="password" formControlName="smtpPassword"></kendo-textbox>
    </kendo-formfield>
  </div>

  <div class="d-flex gap-10 flex-wrap">
    <button type="submit" kendoButton>
      {{ 'AbpSettingManagement::Save' | abpLocalization }}
    </button>
    <button
      type="button"
      (click)="openSendEmailModal()"
      kendoButton
      *abpPermission="emailingPolicy"
    >
      {{ 'AbpSettingManagement::SendTestEmail' | abpLocalization }}
    </button>
  </div>
</form>

<div class="authentication-settings">
  <kendo-dialog
    title="  {{ 'SendTestEmail' | abpLocalization }}"
    *ngIf="isAddEditOpened"
    (close)="close('cancel')"
    [width]="450"
  >
    <form [formGroup]="emailTestForm" id="emailTestForm" class="k-form k-form-md">
      <kendo-formfield class="mb-20">
        <kendo-label
          labelCssClass="k-form-label"
          text="{{ 'AbpSettingManagement::SenderEmailAddress' | abpLocalization }}"
        >
          <span class="required">*</span></kendo-label
        >
        <kendo-textbox formControlName="senderEmailAddress"></kendo-textbox>
      </kendo-formfield>

      <kendo-formfield class="mb-20">
        <kendo-label
          labelCssClass="k-form-label"
          text="{{ 'AbpSettingManagement::TargetEmailAddress' | abpLocalization }}"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox formControlName="targetEmailAddress"></kendo-textbox>
      </kendo-formfield>

      <kendo-formfield class="mb-20">
        <kendo-label
          labelCssClass="k-form-label"
          text="{{ 'AbpSettingManagement::Subject' | abpLocalization }}"
          ><span class="required">*</span></kendo-label
        >
        <kendo-textbox formControlName="subject"></kendo-textbox>
      </kendo-formfield>

      <kendo-formfield class="mb-20">
        <kendo-label
          labelCssClass="k-form-label"
          text="{{ 'AbpSettingManagement::Body' | abpLocalization }}"
        ></kendo-label>
        <kendo-textarea formControlName="body" [rows]="5"></kendo-textarea>
      </kendo-formfield>
    </form>
    <kendo-dialog-actions>
      <button kendoButton (click)="close('no')" fillMode="outline">Cancel</button>
      <button
        kendoButton
        (click)="emailTestFormSubmit()"
        [disabled]="emailTestForm.invalid && emailTestForm.dirty"
        themeColor="primary"
      >
        {{ 'AbpTenantManagement::Send' | abpLocalization }}
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
