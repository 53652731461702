import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { DrawerMode } from '@progress/kendo-angular-layout';
import { ProgressBarOrientation } from '@progress/kendo-angular-progressbar';
import { RolesRights } from 'src/core/enums/rights';
import { HeaderDataEnum } from '../shared/enum/header-data-enum';
import { filter } from 'rxjs';
import { QMenuComponent } from 'src/environments/environment';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @ViewChild('qMenu') QMenu: QMenuComponent;
  public mode: DrawerMode = 'push';

  isMenuOverlay = false;
  isAdmin = false;

  public logoImage = 'assets/images/logo/logo.svg';
  public iconlogoImage = 'assets/images/logo/logo-mob.png';
  public profileImage = 'assets/images/user.png';
  public downarrowImage = 'assets/images/arrow.svg';

  public menuList: Array<any> = [
    {
      text: 'Dashboard',
      icon: 'fas fa-home',
      path: '/dashboard',
      visible: true,
      headerText: 'Welcome To Dashboard',
      id: 0,
      selected: true,
    },
    {
      text: 'Forms',
      icon: 'far fa-plus-square',
      path: '/form-List',
      visible: this.permissionService.getGrantedPolicy(RolesRights.QForms_Form),
      headerText: 'Create Form',
      id: 1,
    },
    {
      text: 'Groups',
      icon: 'far fa-clone',
      path: '/group-List',
      visible: this.permissionService.getGrantedPolicy(RolesRights.QForms_Group),
      headerText: 'Groups',
      id: 2,
    },
    {
      text: 'Administration',
      icon: 'far fa-user-lock',
      visible:
        this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
        this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users) ||
        this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants) ||
        this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
      id: 3,
      hasChildren: true,
    },
    {
      text: 'Identity Management',
      icon: 'far fa-id-card',
      visible:
        this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
        this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
      id: 4,
      parentId: 3,
      hasChildren: true,
    },
    {
      text: 'Tenant Management',
      icon: 'far fa-users-cog',
      visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
      id: 5,
      parentId: 3,
      hasChildren: true,
    },
    {
      text: 'Settings',
      icon: 'far fa-cog',
      path: '/setting-management',
      visible: this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
      headerText: 'Settings',
      id: 6,
      parentId: 3,
    },
    {
      text: 'Tenants',
      icon: 'far fa-user-friends',
      path: '/tenant-management/tenants',
      visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
      headerText: 'Tenants',
      id: 7,
      parentId: 5,
    },
    {
      text: 'Roles',
      icon: 'far fa-clipboard-list',
      path: '/identity/roles',
      visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles),
      headerText: 'Roles',
      id: 8,
      parentId: 4,
    },
    {
      text: 'Users',
      icon: 'far fa-user',
      path: '/identity/users',
      visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
      headerText: 'Users',
      id: 9,
      parentId: 4,
    },
  ];

  public headerDataList: Array<{ text: string; value: number }> = [
    { text: HeaderDataEnum[HeaderDataEnum.Setting], value: HeaderDataEnum.Setting },
    { text: HeaderDataEnum[HeaderDataEnum['Log out']], value: HeaderDataEnum['Log out'] },
  ];

  //#region old

  public donutGraphData = [
    {
      kind: 'Hydroelectric',
      share: 0.175,
    },
    {
      kind: 'Nuclear',
      share: 0.238,
    },
    {
      kind: 'Coal',
      share: 0.118,
    },
    {
      kind: 'Solar',
      share: 0.052,
    },
    {
      kind: 'Wind',
      share: 0.225,
    },
    {
      kind: 'Other',
      share: 0.192,
    },
  ];

  orientation: ProgressBarOrientation = 'horizontal';

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.category;
  }

  lineGraphData = [123, 276, 310, 212, 240, 156, 98];
  public pregressBarData = [
    { name: 'Asia', data: 50 },
    { name: 'India', data: 10 },
    { name: 'USA', data: 98 },
    { name: 'Germany', data: 60 },
  ];

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated;
  }
  dashbordCount: any;
  isDashbordCount = false;
  currentUser = '';

  //#endregion

  constructor(
    private permissionService: PermissionService,
    public authService: AuthService,
    private router: Router,
    private config: ConfigStateService,
  ) {
    this.currentUser = this.config.getOne('currentUser').name;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          !this.authService.isAuthenticated &&
          event.url !== '/TrialSignup' &&
          event.url !== '/form-success'
        ) {
          this.router.navigate(['account']);
        }

        const selectedItem = this.findSelectedItemByPath(this.menuList, this.router.url);
        if (selectedItem) {
          selectedItem.selected = true;
          this.QMenu.headerText = selectedItem.text;
          this.QMenu.tempHeaderText = selectedItem.text;
        }
      });

    this.isAdmin = this.permissionService.getGrantedPolicy('AbpIdentity.Roles');

    const isPrivateFormURL = localStorage.getItem('isPrivateFormURL');
    const isPrivateRender = localStorage.getItem('isPrivateRender');

    if (this.authService.isAuthenticated && isPrivateFormURL) {
      const redirectURL = isPrivateFormURL;
      localStorage.removeItem('isPrivateFormURL');

      if (!isPrivateRender) {
        localStorage.setItem('isPrivateRender', JSON.stringify(true));
        this.router.navigateByUrl(redirectURL);
      }
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setHeaderText();
    });
  }

  ngAfterViewInit(): void {
    this.setHeaderText();
  }

  findSelectedItemByPath(items: any[], path: string): any {
    items.forEach(item => (item.selected = false));
    for (const item of items) {
      if (item.path === path) {
        return item;
      }
      if (item.children) {
        const childItem = this.findSelectedItemByPath(item.children, path);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  onClick(url) {
    let splitUrl = this.router.url.split(';');
    let currentPageUrl = splitUrl[0];
    if (url === currentPageUrl) {
      this.router.navigate([currentPageUrl]);
      setTimeout(() => {
        location.reload();
      }, 0);
    }
  }

  onHeaderClick(event) {
    switch (event.value) {
      case HeaderDataEnum.Setting:
        this.router.navigateByUrl('account/manage');
        break;
      case HeaderDataEnum['Log out']:
        this.authService.logout();
        break;
      default:
        break;
    }
  }

  login(): void {
    this.authService.navigateToLogin();
  }

  ngOnInit() {
    this.setMenuMode();
    window.addEventListener('resize', () => {
      this.setMenuMode();
    });
  }

  setMenuMode(): void {
    this.isMenuOverlay = window.outerWidth < 1024;
    if (this.isMenuOverlay) {
      this.mode = 'overlay';
    } else {
      this.mode = 'push';
    }
  }

  setHeaderText(): void {
    const selectedItem = this.findSelectedItemByPath(this.menuList, this.router.url);
    if (selectedItem) {
      selectedItem.selected = true;
      this.QMenu.headerText = selectedItem.headerText;
      this.QMenu.tempHeaderText = selectedItem.headerText;
    }
  }
}
