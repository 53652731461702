<div class="tenant-container">
  <div *ngIf="!isSaved" class="tenant-card align-items-center justify-content-center">
    <div>
      <div class="header-contianer">
        <div class="d-flex align-items-center gap-10 mb-24">
          <img src="assets/images/logo/logo.svg" alt="logo" width="132" height="32" />
        </div>
        <div>
          <h4 class="header-text">Create new tenant</h4>
          <p class="header-desc">Hello! Let’s create new tenant</p>
        </div>
      </div>
      <div>
        <form class="k-form k-form-md mt-3" [formGroup]="tenantForm">
          <fieldset class="k-form-fieldset">
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="name"
                text="Tenant Name"
                class="d-flex align-items-baseline"
                ><span class="required">*</span></kendo-label
              >
              <kendo-textbox formControlName="name" placeholder="Tenant Name" #name></kendo-textbox>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="email"
                text="Admin Email Address"
                class="d-flex align-items-baseline"
                ><span class="required">*</span></kendo-label
              >
              <kendo-textbox
                formControlName="adminEmail"
                placeholder="Admin Email Address"
                #email
              ></kendo-textbox>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="password"
                text="Admin Password"
                class="d-flex align-items-baseline"
                ><span class="required">*</span></kendo-label
              >
              <kendo-textbox
                #password
                type="{{ passwordVisible ? 'text' : 'password' }}"
                placeholder="Admin Password"
                formControlName="adminPwd"
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button
                    kendoButton
                    class="password-btn"
                    type="button"
                    (click)="togglePasswordVisibility()"
                  >
                    <i class="fa" [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                  </button>
                </ng-template>
              </kendo-textbox>
            </kendo-formfield>
          </fieldset>
        </form>
      </div>
      <div class="mt-2" [ngClass]="{ 'curser-not-allowd': tenantForm?.invalid }">
        <div class="mb-10">
          <button
            kendoButton
            status="success"
            (click)="save()"
            [disabled]="tenantForm?.invalid"
            class="d-flex wp-100 mt-10"
          >
            {{ 'AbpTenantManagement::Save' | abpLocalization }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isSaved" class="tenant-success-popup">
    <div class="d-flex justify-content-center align-items-center flex-column">
      <img width="132" height="32" src="assets/images/logo/logo.svg" />
      <hr />
    </div>
    <div
      class="text-align-center d-flex align-items-center justify-content-center flex-column gap-10 success-body"
    >
      <img src="assets/images/tenant-success.png" class="mb-24" />
      <p class="font-size-20 mb-0 fw-500 message">You have onboarded successfully!</p>
      <p class="text-align-center description">
        Your personalized URL has been shared on your Email. Please check your Email Id & verify
      </p>
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
