<div class="q-content" *ngIf="this.authService.isAuthenticated">
  <div class="q-conetnt-header d-flex justify-content-end mb-20"></div>
  <div class="q-content-bg">
    <div class="form-container mb-30">
      <form [formGroup]="formField">
        <div class="d-flex-row">
          <div class="d-flex gap-10 d-flex-col-sm-9 d-flex-col-xs-12 d-flex-col">
            <div class="form-icon"><i class="fal fa-newspaper"></i></div>
            <div class="form-content-deatils wp-100">
              <div class="mb-5">
                <kendo-formfield>
                  <kendo-textbox
                    placeholder="| Add your Form Title"
                    formControlName="title"
                    required
                    class="create-form-field create-form-field-title"
                  ></kendo-textbox>
                </kendo-formfield>
              </div>
              <div class="mb-5">
                <kendo-formfield>
                  <kendo-textarea
                    placeholder="| Add Description"
                    formControlName="description"
                    resizable="auto"
                    class="create-form-field"
                  ></kendo-textarea>
                </kendo-formfield>
              </div>
              <div class="mb-5">
                <button
                  kendoButton
                  (click)="saveBanner()"
                  fillMode="flat"
                  class="add-banner-button"
                >
                  Add Banner Content
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex-col-sm-3 d-flex-col-xs-12 d-flex-col">
            <kendo-dropdownlist
              [data]="publishStateList"
              textField="key"
              valueField="value"
              [valuePrimitive]="true"
              formControlName="publishState"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </form>
    </div>
    <div>
      <span
        ><form-builder
          #formioElement
          [form]="form"
          [options]="this.options"
          (change)="onChange($event)"
        ></form-builder
      ></span>
      <div class="text-right">
        <kendo-button kendoButton (click)="saveForm()" [disabled]="formField.invalid">{{
          buttonName
        }}</kendo-button>
      </div>
    </div>
  </div>
</div>

<kendo-dialog
  title="Add Banner Content"
  *ngIf="isShowBannerPopup"
  (close)="closeBanner()"
  [width]="450"
>
  <kendo-editor [(ngModel)]="bannerContent" style="height: auto"></kendo-editor>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeBanner()" fillMode="outline">Cancel</button>
    <button kendoButton (click)="closeBanner()" themeColor="primary">
      {{ buttonName }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
