<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>Email</div>
</kendo-dialog-titlebar>
<div>
  <form [formGroup]="emailForm" class="k-form k-form-md">
    <kendo-formfield class="mt-0 mb-20">
      <kendo-label text="To" [for]="To"><span class="required">*</span> </kendo-label>
      <kendo-multiselect
        #To
        [data]="emails"
        textField="emails"
        formControlName="to"
        (keyup)="onToChange($event)"
        class="mt-10"
      ></kendo-multiselect>
    </kendo-formfield>
    <div class="mb-20" *ngIf="existingUserList.length > 0">
      <div class="user-list">
        <kendo-listview
          [data]="existingUserList"
          containerClass="k-d-flex k-flex-col k-flex-nowrap"
          [itemClass]="{ 'item-border': true }"
        >
          <ng-template kendoListViewHeaderTemplate>
            <div class="list-header fw-500">List of user who already have access.</div>
          </ng-template>
          <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-i="index">
            <span *ngIf="!dataItem.isUser"> {{ i + 1 }}. {{ dataItem.name }}</span>
            <span *ngIf="dataItem.isUser"> {{ i + 1 }}. {{ dataItem.email }}</span>
          </ng-template>
        </kendo-listview>
      </div>
    </div>

    <kendo-formfield class="mb-20">
      <kendo-label [for]="subject" text="Subject"><span class="required">*</span></kendo-label>
      <kendo-textbox
        formControlName="subject"
        placeholder="Subject"
        #subject
        class="mt-10"
      ></kendo-textbox>
    </kendo-formfield>

    <div class="mb-20">
      <kendo-editor formControlName="message"></kendo-editor>
    </div>
  </form>
</div>

<kendo-dialog-actions>
  <kendo-button kendoButton fillMode="outline" (click)="onCloseDialog()">Cancel</kendo-button>
  <kendo-button kendoButton (click)="onSubmit(emailForm.value)"> Submit</kendo-button>
</kendo-dialog-actions>
